import type { AxiosInstance } from 'axios';
import type { ApiPlugin } from '../';
import * as Auth from '../../auth';

export class RefreshTokenPlugin implements ApiPlugin {
  initialize(instance: AxiosInstance): void {
    instance.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const config = err.originalError.config;
        const authStore = Auth.useAuthStore();
        const notAuthUri = !this.isAuthApiUrl(config.url);

        if (notAuthUri && err.code === 401) {
          if (!config._retry) {
            config._retry = true;

            try {
              if (await authStore.refreshAccessToken()) {
                return instance(config);
              } else {
                return this.handleAuthError(err);
              }
            } catch (_error) {
              return this.handleAuthError(_error);
            }
          } else {
            return this.handleAuthError(err);
          }
        }

        return Promise.reject(err);
      }
    );
  }

  private isAuthApiUrl(url?: string) {
    return url?.startsWith(`/auth`);
  }

  private async handleAuthError(err: any) {
    const authStore = Auth.useAuthStore();

    await authStore.logout(true);
    return Promise.reject(err);
  }
}
