import {
  type DatePickerConfig,
  type FormConfig,
  type RadioGroupConfig,
  type SelectConfig,
  type CheckboxGroupConfig,
  type TextareaConfig,
  type ComboboxConfig,
  type ArrayLengthValidatorSettings,
  type FormGroupConfig,
  HtmlLabel
} from '@common/index';
import type { DateIsBetweenValidatorSettings } from '../../validators';

export const postOpStepFormConfig: FormConfig = {
  name: 'Post Op',
  plugins: [
    {
      plugin: 'progress'
    }
  ],
  attributes: {
    color: 'primary',
    variant: 'outlined',
    density: 'compact'
  },
  settings: {
    validation: {
      onInput: false
    }
  },
  children: [
    {
      name: 'pathologicalTNM',
      component: 'formGroup',
      attributes: {
        label: 'REGISTER.1000.TEXT.PATHOLOGICAL',
        hideDetails: true
      },
      sheetAttributes: {
        border: false
      },
      settings: {
        needTranslate: true,
        labelComponent: HtmlLabel,
        grid: { cols: 12, md: 6 }
      },
      children: [
        {
          name: 'pt',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: { cols: 12 },
            direction: 'inline',
            items: [
              {
                label: 'REGISTER.1000.ANSWER.PT.0',
                value: 0
              },
              {
                label: 'REGISTER.1000.ANSWER.PT.1',
                value: 1
              },
              {
                label: 'REGISTER.1000.ANSWER.PT.2',
                value: 2
              },
              {
                label: 'REGISTER.1000.ANSWER.PT.3',
                value: 3
              },
              {
                label: 'REGISTER.1000.ANSWER.PT.4',
                value: 4
              },
              {
                label: 'REGISTER.1000.ANSWER.PT.5',
                value: 5
              },
              {
                label: 'REGISTER.1000.ANSWER.PT.6',
                value: 6
              },
              {
                label: 'REGISTER.1000.ANSWER.PT.7',
                value: 7
              },
              {
                label: 'REGISTER.1000.ANSWER.PT.8',
                value: 8
              },
              {
                label: 'REGISTER.1000.ANSWER.PT.9',
                value: 9
              },
              {
                label: 'REGISTER.1000.ANSWER.PT.10',
                value: 10
              },
              {
                label: 'REGISTER.1000.ANSWER.PT.11',
                value: 11
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PT',
            itemTitle: 'label'
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as RadioGroupConfig,
        {
          name: 'pn',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: { cols: 12 },
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.PN.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.PN.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.PN.2',
                value: 2
              },
              {
                title: 'REGISTER.1000.ANSWER.PN.3',
                value: 3
              },
              {
                title: 'REGISTER.1000.ANSWER.PN.4',
                value: 4
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PN'
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as RadioGroupConfig,
        {
          name: 'pm',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: { cols: 12 },
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.PM.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.PM.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.PM.2',
                value: 2
              },
              {
                title: 'REGISTER.1000.ANSWER.PM.3',
                value: 3
              },
              {
                title: 'REGISTER.1000.ANSWER.PM.4',
                value: 4
              },
              {
                title: 'REGISTER.1000.ANSWER.PM.5',
                value: 5
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PM'
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as RadioGroupConfig,
        {
          name: 'resectionLine',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.RESECTIONLINE.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.RESECTIONLINE.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.RESECTIONLINE.2',
                value: 2
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.RESECTIONLINE'
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as RadioGroupConfig
      ]
    } as FormGroupConfig,
    {
      name: 'oesophagusPathologicalTNM',
      component: 'formGroup',
      attributes: {
        label: 'REGISTER.1000.TEXT.PATHOLOGICAL',
        hideDetails: true
      },
      sheetAttributes: {
        border: false
      },
      settings: {
        needTranslate: true,
        labelComponent: HtmlLabel,
        grid: { cols: 12, md: 6 }
      },
      children: [
        {
          name: 'ptOesophagus',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: { cols: 12 },
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.PTOESOPHAGUS.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.PTOESOPHAGUS.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.PTOESOPHAGUS.2',
                value: 2
              },
              {
                title: 'REGISTER.1000.ANSWER.PTOESOPHAGUS.3',
                value: 3
              },
              {
                title: 'REGISTER.1000.ANSWER.PTOESOPHAGUS.4',
                value: 4
              },
              {
                title: 'REGISTER.1000.ANSWER.PTOESOPHAGUS.5',
                value: 5
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PTOESOPHAGUS'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'pnOesophagus',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: { cols: 12 },
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.PNOESOPHAGUS.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.PNOESOPHAGUS.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.PNOESOPHAGUS.2',
                value: 2
              },
              {
                title: 'REGISTER.1000.ANSWER.PNOESOPHAGUS.3',
                value: 3
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PNOESOPHAGUS'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'pmOesophagus',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: { cols: 12 },
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.PMOESOPHAGUS.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.PMOESOPHAGUS.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.PMOESOPHAGUS.2',
                value: 2
              },
              {
                title: 'REGISTER.1000.ANSWER.PMOESOPHAGUS.3',
                value: 3
              },
              {
                title: 'REGISTER.1000.ANSWER.PMOESOPHAGUS.4',
                value: 4
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PMOESOPHAGUS'
          },
          validators: []
        } as RadioGroupConfig
      ]
    } as FormGroupConfig,
    {
      name: 'complication',
      component: 'combobox',
      settings: {
        needTranslate: true,
        needItemTranslate: true,

        items: [
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.0',
            value: 0
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.1',
            value: 1
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.2',
            value: 2
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.3',
            value: 3
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.4',
            value: 4
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.5',
            value: 5
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.6',
            value: 6
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.7',
            value: 7
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.8',
            value: 8
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.9',
            value: 9
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.10',
            value: 10
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.11',
            value: 11
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.12',
            value: 12
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.13',
            value: 13
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.14',
            value: 14
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.15',
            value: 15
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.16',
            value: 16
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.17',
            value: 17
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.18',
            value: 18
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.19',
            value: 19
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.20',
            value: 20
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.21',
            value: 21
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.22',
            value: 22
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.23',
            value: 23
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.24',
            value: 24
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.25',
            value: 25
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.26',
            value: 26
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.27',
            value: 27
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.28',
            value: 28
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.29',
            value: 29
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.30',
            value: 30
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.31',
            value: 31
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.32',
            value: 32
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.33',
            value: 33
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.34',
            value: 34
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.35',
            value: 35
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.36',
            value: 36
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.37',
            value: 37
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.38',
            value: 38
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.39',
            value: 39
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.40',
            value: 40
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.41',
            value: 41
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.42',
            value: 42
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.43',
            value: 43
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.44',
            value: 44
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.45',
            value: 45
          },
          {
            title: 'REGISTER.1000.ANSWER.COMPLICATION.46',
            value: 46
          }
        ]
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.COMPLICATION',
        multiple: true,
        chips: true,
        closableChips: true
      },
      validators: [
        {
          validator: 'required'
        }
      ]
    } as ComboboxConfig,
    {
      name: 'majorCardiopulmonaryComplications',
      component: 'radioGroup',
      settings: {
        needTranslate: true,
        needItemTranslate: true,
        direction: 'inline',
        // labelComponent: AsyncHtmlFormLabel,
        // itemLabelComponent: AsyncHtmlFormLabel,
        items: [
          {
            title: 'REGISTER.1000.ANSWER.MAJORCARDIOPULMONARYCOMPLICATIONS.0',
            value: 0
          },
          {
            title: 'REGISTER.1000.ANSWER.MAJORCARDIOPULMONARYCOMPLICATIONS.1',
            value: 1
          }
        ]
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.MAJORCARDIOPULMONARYCOMPLICATIONS'
      },
      validators: [
        {
          validator: 'required'
        }
      ]
    } as RadioGroupConfig,
    {
      name: 'dateOfDeath',
      component: 'datepicker',
      settings: {
        needTranslate: true,
        grid: {
          cols: 12,
          md: 6
        }
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.DATEOFDEATH',
        returnFormatted: 'yyyy-MM-dd',
        format: 'yyyy-MM-dd',
        closeCalendarOnPick: true
      },
      validators: [
        {
          validator: 'dateIsBetween',
          settings: {
            dateFrom: new Date('2000.01.01'),
            dateTo: new Date(),
            allowEqual: true
          } as DateIsBetweenValidatorSettings
        }
      ]
    } as DatePickerConfig,
    {
      name: 'causeOfDeath',
      component: 'radioGroup',
      settings: {
        needTranslate: true,
        needItemTranslate: true,
        direction: 'inline',
        items: [
          {
            title: 'REGISTER.1000.ANSWER.CAUSEOFDEATH.0',
            value: 0
          },
          {
            title: 'REGISTER.1000.ANSWER.CAUSEOFDEATH.1',
            value: 1
          },
          {
            title: 'REGISTER.1000.ANSWER.CAUSEOFDEATH.2',
            value: 2
          }
        ]
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.CAUSEOFDEATH'
      },
      validators: []
    } as RadioGroupConfig,
    {
      name: 'outcomeAtDischarge',
      component: 'radioGroup',
      settings: {
        needTranslate: true,
        needItemTranslate: true,
        direction: 'inline',
        items: [
          {
            title: 'REGISTER.1000.ANSWER.OUTCOMEATDISCHARGE.0',
            value: 0
          },
          {
            title: 'REGISTER.1000.ANSWER.OUTCOMEATDISCHARGE.1',
            value: 1
          }
        ]
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.OUTCOMEATDISCHARGE'
      },
      validators: [
        {
          validator: 'required'
        }
      ]
    } as RadioGroupConfig,
    {
      name: 'outcomeAt30Days',
      component: 'radioGroup',
      settings: {
        needTranslate: true,
        needItemTranslate: true,
        direction: 'inline',
        items: [
          {
            title: 'REGISTER.1000.ANSWER.OUTCOMEAT30DAYS.0',
            value: 0
          },
          {
            title: 'REGISTER.1000.ANSWER.OUTCOMEAT30DAYS.1',
            value: 1
          }
        ]
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.OUTCOMEAT30DAYS'
      },
      validators: [
        {
          validator: 'required'
        }
      ]
    } as RadioGroupConfig,
    {
      name: 'notes',
      component: 'textarea',
      settings: {
        needTranslate: true,
        grid: 12
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.NOTES',
        counter: 500
      },
      validators: [
        {
          validator: 'length',
          settings: {
            min: 0,
            max: 500
          }
        }
      ]
    } as TextareaConfig,
    {
      name: 'unexpectedReturnToOr',
      component: 'radioGroup',
      settings: {
        needTranslate: true,
        needItemTranslate: true,
        direction: 'inline',
        items: [
          {
            title: 'REGISTER.1000.ANSWER.UNEXPECTEDRETURNTOOR.0',
            value: 0
          },
          {
            title: 'REGISTER.1000.ANSWER.UNEXPECTEDRETURNTOOR.1',
            value: 1
          }
        ]
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.UNEXPECTEDRETURNTOOR'
      },
      validators: []
    } as RadioGroupConfig,
    {
      name: 'readmissionToAnyHospitalWithin30DaysDischarge',
      component: 'radioGroup',
      settings: {
        needTranslate: true,
        needItemTranslate: true,
        direction: 'inline',
        items: [
          {
            title: 'REGISTER.1000.ANSWER.READMISSIONTOANYHOSPITALWITHIN30DAYSDISCHARGE.0',
            value: 0
          },
          {
            title: 'REGISTER.1000.ANSWER.READMISSIONTOANYHOSPITALWITHIN30DAYSDISCHARGE.1',
            value: 1
          },
          {
            title: 'REGISTER.1000.ANSWER.READMISSIONTOANYHOSPITALWITHIN30DAYSDISCHARGE.2',
            value: 2
          }
        ]
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.READMISSIONTOANYHOSPITALWITHIN30DAYSDISCHARGE'
      },
      validators: []
    } as RadioGroupConfig,
    {
      name: 'outcomeAt90Days',
      component: 'radioGroup',
      settings: {
        needTranslate: true,
        needItemTranslate: true,
        direction: 'inline',
        items: [
          {
            title: 'REGISTER.1000.ANSWER.OUTCOMEAT90DAYS.0',
            value: 0
          },
          {
            title: 'REGISTER.1000.ANSWER.OUTCOMEAT90DAYS.1',
            value: 1
          },
          {
            title: 'REGISTER.1000.ANSWER.OUTCOMEAT90DAYS.2',
            value: 2
          }
        ]
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.OUTCOMEAT90DAYS'
      },
      validators: []
    } as RadioGroupConfig,
    {
      name: 'operativeDeath',
      component: 'radioGroup',
      settings: {
        needTranslate: true,
        needItemTranslate: true,
        direction: 'inline',
        items: [
          {
            title: 'REGISTER.1000.ANSWER.OPERATIVEDEATH.0',
            value: 0
          },
          {
            title: 'REGISTER.1000.ANSWER.OPERATIVEDEATH.1',
            value: 1
          },
          {
            title: 'REGISTER.1000.ANSWER.OPERATIVEDEATH.2',
            value: 2
          }
        ]
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.OPERATIVEDEATH'
      },
      validators: []
    } as RadioGroupConfig,
    {
      name: 'otherComplication',
      component: 'textarea',
      settings: {
        needTranslate: true,
        grid: 12
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.OTHERCOMPLICATION',
        counter: 500
      },
      validators: [
        {
          validator: 'length',
          settings: {
            min: 0,
            max: 500
          }
        }
      ]
    },
    {
      name: 'gradingComp',
      component: 'checkboxGroup',
      settings: {
        needTranslate: true,
        needItemTranslate: true,
        direction: 'inline',
        items: [
          {
            title: 'REGISTER.1000.ANSWER.GRADINGCOMP.0',
            value: 0
          },
          {
            title: 'REGISTER.1000.ANSWER.GRADINGCOMP.1',
            value: 1
          },
          {
            title: 'REGISTER.1000.ANSWER.GRADINGCOMP.2',
            value: 2
          },
          {
            title: 'REGISTER.1000.ANSWER.GRADINGCOMP.3',
            value: 3
          },
          {
            title: 'REGISTER.1000.ANSWER.GRADINGCOMP.4',
            value: 4
          }
        ]
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.GRADINGCOMP',
        multiple: true
      },
      validators: [
        {
          validator: 'checkboxLength',
          settings: {
            min: 0,
            max: 3,
            inclusive: true
          } as ArrayLengthValidatorSettings
        }
      ]
    } as CheckboxGroupConfig,
    {
      name: 'adjuvantTreatment',
      component: 'select',
      settings: {
        needTranslate: true,
        needItemTranslate: true,
        grid: { cols: 12, md: 6 },
        items: [
          {
            label: 'REGISTER.1000.ANSWER.ADJUVANTTREATMENT.0',
            value: 0
          },
          {
            label: 'REGISTER.1000.ANSWER.ADJUVANTTREATMENT.1',
            value: 1
          },
          {
            label: 'REGISTER.1000.ANSWER.ADJUVANTTREATMENT.2',
            value: 2
          },
          {
            label: 'REGISTER.1000.ANSWER.ADJUVANTTREATMENT.3',
            value: 3
          },
          {
            label: 'REGISTER.1000.ANSWER.ADJUVANTTREATMENT.4',
            value: 4
          },
          {
            label: 'REGISTER.1000.ANSWER.ADJUVANTTREATMENT.5',
            value: 5
          }
        ]
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.ADJUVANTTREATMENT',
        itemTitle: 'label'
      },
      validators: []
    } as SelectConfig
  ]
};
