import type { FormConfig, TextConfig } from '@common/index';

export const calculationsStepFormConfig: FormConfig = {
  name: 'Calculations',
  plugins: [{ plugin: 'progress' }],
  attributes: {
    color: 'primary',
    variant: 'outlined',
    density: 'compact'
  },
  settings: { validation: { onInput: false } },
  children: [
    {
      name: 'ageAtSurgery',
      component: 'text',
      settings: {
        needTranslate: true,
        grid: 12,
        defaultValue: 0
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.AGEATSURGERY',
        type: 'number',
        step: 1
      },
      validators: [{ validator: 'required' }]
    } as TextConfig,
    {
      name: 'predictedMorbidity',
      component: 'text',
      settings: {
        needTranslate: true,
        grid: 12,
        defaultValue: 0
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.PREDICTEDMORBIDITY',
        type: 'number',
        step: 1
      },
      validators: [{ validator: 'required' }]
    } as TextConfig,
    {
      name: 'predictedMortality',
      component: 'text',
      settings: {
        needTranslate: true,
        grid: 12,
        defaultValue: 0
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.PREDICTEDMORTALITY',
        type: 'number',
        step: 1
      },
      validators: [{ validator: 'required' }]
    } as TextConfig
  ]
};
