import { formService } from '@common/index';
import { AsyncAutocompleteAdditionalParamPlugin, SetTargetElementEditablePlugin } from '../plugins';

export const registerPlugins = () => {
  formService.registerPlugin('asyncAutocompleteAdditionalParam', AsyncAutocompleteAdditionalParamPlugin);
  formService.registerPlugin('setTargetElementEditablePlugin', SetTargetElementEditablePlugin);
};

export const initForms = () => {
  registerPlugins();
};
