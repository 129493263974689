import { FormElement, FormElementPlugin, type FormElementPluginConfig, type FormPluginSettings } from '@common/index';

export interface InitVisibilityPluginSettings extends FormPluginSettings {
  setVisibility: (_element: FormElement) => void;
}

export type InitVisibilityPluginConfig = FormElementPluginConfig<InitVisibilityPluginSettings>;

export class InitVisibilityPlugin extends FormElementPlugin<InitVisibilityPluginSettings> {
  public constructor() {
    super();
    this._name = 'initVisibilityPlugin';
  }

  public override onInitialize(_element: FormElement<any>): void {
    this._settings.setVisibility(_element);
  }
}
