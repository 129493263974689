import { Form, FormElement, FormElementPlugin, type FormElementPluginConfig, type FormPluginSettings } from '@common/index';
import type { FormHierarchyItem } from '../models';
import { getElement } from '../utils';

export interface ChangeVisibilityPluginSettings extends FormPluginSettings {
  targetFields: TargetFieldConfig[];
}

export type ChangeVisibilityPluginConfig = FormElementPluginConfig<ChangeVisibilityPluginSettings>;

export class ChangeVisibilityPlugin extends FormElementPlugin<ChangeVisibilityPluginSettings> {
  public constructor() {
    super();
    this._name = 'changeVisibilityPlugin';
  }

  public override onInput(_element: FormElement<any>, _value: any): void {
    const form = _element.form as Form;
    this._settings.targetFields.forEach((targetField) => {
      const targetElement = getElement(targetField.formHierarchyItem, form);
      targetField.setVisibility(targetElement, _value);
    });
  }
}

export interface TargetFieldConfig {
  formHierarchyItem: FormHierarchyItem;
  setVisibility: (targetElement: FormElement, value: any) => void;
}
