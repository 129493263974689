import agendaIcon from './agenda.svg';
import collectionIcon from './collection.svg';
import computerIcon from './computer.svg';
import lockIcon from './lock.svg';
import notePadIcon from './notepad.svg';
import presentationIcon from './presentation.svg';

export const customIcons = {
  customAgenda: agendaIcon,
  customCollection: collectionIcon,
  customComputer: computerIcon,
  customLock: lockIcon,
  customNotepad: notePadIcon,
  customPresentation: presentationIcon
};
