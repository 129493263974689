import type { FormElement } from './form-element.model';
import { all as mergeAll } from 'deepmerge';

export abstract class FormValidator<TSettings extends FormValidatorSettings = FormValidatorSettings> {
  protected _name = '';
  protected _settings: TSettings = {} as TSettings;
  protected _enabled = true;
  protected _valid: boolean | undefined = undefined;
  protected _messages: FormValidatorMessage[] = [];

  public validate(value: any, context: FormValidatorContext): Promise<FormValidatorResult> {
    return new Promise((resolve) => {
      this.onValidate(value, context).then(() => {
        resolve({
          valid: this.valid !== false,
          messages: this.messages
        });
      });
    });
  }

  protected abstract onValidate(value: any, context: FormValidatorContext): Promise<void>;

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get settings(): TSettings {
    return this._settings;
  }

  public set settings(settings: Partial<TSettings>) {
    this._settings = mergeAll([this._settings, settings]) as TSettings;
  }

  public get enabled() {
    return this._enabled;
  }

  public set enabled(enabled: boolean) {
    this._enabled = enabled;
  }

  public get valid() {
    return this._valid;
  }

  public set valid(valid) {
    this._valid = valid;
  }

  public get messages() {
    return this._messages;
  }

  public set messages(messages) {
    this._messages = messages;
  }

  public reset = () => {
    this._valid = undefined;
    this._messages = [];
  };

  public addMessage = (message: string, type = 'error', translatePlaceholders: any = undefined) => {
    this._messages.push({
      validator: this._name,
      type,
      message,
      settings: {
        needTranslate: this._settings.needTranslate || false,
        translatePlaceholders: translatePlaceholders
      }
    });
  };
}

export interface FormValidatorConfig<TSettings extends FormValidatorSettings = FormValidatorSettings> {
  validator: string;
  settings?: TSettings;
}

export interface FormValidatorSettings {
  invalidMessage: string;
  needTranslate: boolean;
}

export interface FormValidatorContextSettings {
  triggerRelatedField: boolean;
}

export interface FormValidatorContext {
  element: FormElement<any>;
  values: any;
  settings?: FormValidatorContextSettings;
}

export interface FormValidatorResult {
  valid: boolean;
  messages: FormValidatorMessage[];
}

export interface FormValidatorMessage {
  validator: string;
  type: 'error' | 'warning' | 'info' | string;
  message: string;
  settings: {
    needTranslate: boolean;
    translatePlaceholders?: any;
  };
}

export interface FormValidationOptions {
  validateDisabled?: boolean;
  validateInvisible?: boolean;
  validateChildren?: boolean;
  skipMessages?: boolean;
  skipPlugins?: boolean;
}

export type ValidatorClass = new () => FormValidator;
