import type {
  Form,
  FormElement,
  FormElementPluginConfig,
  FormElementState,
  FormPluginSettings,
  SelectableFormElement
} from '@common/lib/modules/form';
import { FormElementPlugin } from '@common/lib/modules/form';

export interface SetTargetElementEditablePluginSettings extends FormPluginSettings {
  targetElementName: string;
  targetState: boolean;
}

export type SetTargetElementEditablePluginConfig = FormElementPluginConfig<SetTargetElementEditablePluginSettings>;

export class SetTargetElementEditablePlugin extends FormElementPlugin {
  public constructor() {
    super();
    this._name = 'setTargetElementEditablePlugin';
  }

  public override onValueChange(_element: FormElement<any, FormElementState>, _value: any): void {
    if (!_value) {
      return;
    }

    const form = _element.form as Form;
    const targetElement = form.getChild(this.settings.targetElementName) as SelectableFormElement;

    targetElement.enabled = true;
  }
}
