import { Permission } from '@common/index';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/cases',
    component: () => import('../pages/CasesPage.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'cases',
        component: () => import('../pages/CaseListPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'leader',
        name: 'casesForLeader',
        component: () => import('../pages/CaseLeaderListPage.vue'),
        meta: {
          requiresAuth: true,
          permission: Permission.MENU_CENTER_CASES
        }
      },
      {
        path: 'details/:caseId',
        name: 'caseDetails',
        component: () => import('../pages/CaseDetailsPage.vue'),
        props: true,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/cases/create',
    name: 'createCase',
    component: () => import('../pages/CaseEditorPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cases/edit/:caseId',
    name: 'editCase',
    component: () => import('../pages/CaseEditorPage.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  }
];

export default routes;
