export default {
  dataTable: {
    viewMode: {
      table: 'Table view',
      grid: 'Grid view'
    },
    sort: {
      sort: 'Sort',
      none: 'Default'
    },
    selectAll: 'Select all'
  },
  dataManager: {
    paginator: {
      itemsPerPage: 'Items per page'
    }
  },
  modal: {
    close: 'Close',
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  validation: {
    required: 'The field is required!',
    companyNumber: 'Not valid company number format! (BS-CF-NNNNNN)',
    minChars: 'Minimum {min} character!',
    maxChars: 'Maximum {max} character!',
    email: 'Not valid email format!'
  },
  form: {
    input: {
      datePicker: {
        selectDate: 'Choose a date'
      },
      formArray: {
        confirmClearTitle: 'Clear all items',
        confirmClearText: 'Are you sure you want to clear all the elements?',
        confirmRemoveTitle: 'Remove item',
        confirmRemoveText: 'Are you sure you want to remove the element?'
      }
    },
    validator: {
      required: {
        invalidMessage: 'Field is required'
      },
      dateIsAfter: {
        invalidMessage: 'Date must be after {dateToCompare}'
      }
    }
  },
  login: {
    signIn: 'Sign in',
    email: 'E-mail',
    password: 'Password',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password',
    backToLogin: 'Back to login',
    sendEmail: 'Send email',
    username: 'Username'
  },
  notification: {
    default: {
      success: 'The operation was successful',
      error: 'There was an error while processing your request'
    }
  },
  auth: {
    newPassword: 'New password',
    passwordConfirm: 'Confirm password',
    setPassword: 'Set password',
    name: 'Name',
    email: 'Email',
    phoneNumber: 'Phone number',
    personalData: 'Personal details',
    changePassword: 'Change password',
    currentPassword: 'Current password',
    validation: {
      passwordMustMatch: 'Passwords must match!',
      passwordMinLength: 'The password must be at least {min} characters long!',
      passwordFormat: 'The password must contain a lowercase letter, an uppercase letter, a number and a special character!'
    },
    hint: {
      passwordFormat: 'Password must be at least {min} characters long, contain lowercase, uppercase, number and special character!'
    },
    btn: {
      save: 'Save'
    },
    notification: {
      savePersonalData: {
        success: 'Successful modification!',
        error: 'An error occurred during the modification!'
      },
      saveCustomerDetails: {
        success: 'Successful modification!',
        error: 'An error occurred during the modification!'
      },
      createCustomer: {
        success: 'Successful creation!',
        error: 'An error occurred during creation!'
      },
      changePassword: {
        success: 'Successful password change!',
        error: 'An error occurred while changing your password!'
      },
      api: {
        noActiveAccount: 'Unsuccessful login! The email address or password is not correct!'
      }
    }
  },
  forgotPassword: {
    notification: {
      text: 'The e-mail has been successfully sent!',
      errorText: 'There are no active users with this email address!'
    }
  }
};
