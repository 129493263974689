import type { AsyncAutocompleteConfig, DatePickerConfig, FormConfig } from '@common/index';

export const caseBaseDataFormConfig: FormConfig = {
  name: 'caseBaseDataForm',
  attributes: {
    color: 'primary',
    variant: 'outlined',
    density: 'compact'
  },
  children: [
    {
      name: 'code',
      component: 'text',
      attributes: {
        label: 'case.label.identifier'
      },
      settings: {
        needTranslate: true
      },
      validators: [{ validator: 'required' }]
    },
    {
      component: 'datepicker',
      name: 'operationDate',
      settings: {
        needTranslate: true
      },
      attributes: {
        label: 'case.label.surgeryDate',
        format: 'yyyy-MM-dd',
        displayFormat: 'yyyy-MM-dd',
        closeCalendarOnPick: true
      },
      validators: [
        {
          validator: 'required'
        }
      ]
    } as DatePickerConfig,
    {
      component: 'asyncAutocomplete',
      name: 'surgeons',
      attributes: {
        label: 'case.label.surgeons',
        itemTitle: 'name',
        returnObject: true,
        multiple: true,
        chips: true,
        clearable: true
      },
      settings: {
        backendUrl: '/search-users/member-for-case',
        needTranslate: true,
        grid: {
          cols: 12
        },
        additionalParams: {
          caseUserRole: 'ASSISTANT'
        }
      },
      validators: [
        {
          validator: 'required'
        }
      ]
    } as AsyncAutocompleteConfig,
    {
      component: 'asyncAutocomplete',
      name: 'assistants',
      attributes: {
        label: 'case.label.assistants',
        itemTitle: 'name',
        returnObject: true,
        multiple: true,
        chips: true,
        clearable: true
      },
      settings: {
        backendUrl: '/search-users/member-for-case',
        needTranslate: true,
        grid: {
          cols: 12
        },
        additionalParams: {
          caseUserRole: 'SURGEON'
        }
      }
    } as AsyncAutocompleteConfig
  ]
};
