import { CaseState, CaseStepStatus } from '../models';
import type { CaseData, CaseStep, CaseStepData, CaseStepState, FormHierarchyItem, RegisterStepData } from '../models';
import { caseBaseDataFormConfig } from '../config';
import { customDeepClone } from '@common/lib/utils';
import { createForm, type FormElement, type FormElementConfig, isEmpty, Form, SelectableFormElement } from '@common/lib/modules/form';
import { LUNG_REGISTER_STEPS } from '../config/register-lung';
import { formService } from '@common/index';
import { BasicCalculatedPlugin, ChangeVisibilityPlugin, InitVisibilityPlugin } from '../plugins';
import { DateIsBetweenValidator, NumberIsBetweenValidator } from '../validators';
import { CheckboxLengthValidator } from '../validators/checkbox-length.validator';
import { toRaw } from 'vue';

export function getCaseStateFilterOptions() {
  return [CaseState.DRAFT, CaseState.SUBMITTED, CaseState.DENIED, CaseState.APPROVED, CaseState.CLOSED].map((state) => ({
    label: `case.state.${state}`,
    value: state
  }));
}

export const buildCaseStepForms = async (currentCase?: CaseData, baseStepOnly = false, readonly = false) => {
  const steps = [] as CaseStep<any>[];

  // base step
  const baseStep = await createBaseStep(currentCase, readonly);
  steps.push(baseStep);

  if (baseStepOnly) return steps;

  // case steps
  if (currentCase) {
    for (const caseStepData of currentCase.caseSteps) {
      console.log(toRaw(caseStepData));
      const createdStep = await createCaseStep(caseStepData, readonly);
      steps.push(createdStep);
    }
  }

  return steps;
};

enum SexType {
  female = 0,
  male = 1
}

const thoracotomySelection = 0;
const pneumonectomySelection = 3;
const bilobectomyLobectomyQualifierSelection = 1;
const pneumonectomyQualifierSelections = [1, 2, 3];

export const getFieldsForCalculations = async (currentCase: CaseData) => {
  let filledData = {};

  if (currentCase.caseSteps[0].filledData != null && currentCase.caseSteps[1].filledData != null && currentCase.caseSteps[2].filledData != null) {
    const bmi = currentCase.caseSteps[0].filledData.bmi;
    const gender = currentCase.caseSteps[0].filledData.gender;
    const dateOfBirth = currentCase.caseSteps[0].filledData.dateOfBirth;
    const fev1 = currentCase.caseSteps[1].filledData.spirometry.fev1Litres;
    const surgeryDate = currentCase.operationDate;
    const performedAccess = currentCase.caseSteps[2].filledData.performedProcedures.performedAccess;
    const lungExcisionProcedure = currentCase.caseSteps[2].filledData.performedProcedures.lungSubgroupData.lungExcisionProcedure;
    const bilobectomyLobectomyQualifier =
      currentCase.caseSteps[2].filledData.performedProcedures.lungSubgroupData.bilobectomyGroup.bilobectomyLobectomyQualifier;
    const pneumonectomyQualifier = currentCase.caseSteps[2].filledData.performedProcedures.lungSubgroupData.pneumonectomyGroup.pneumonectomyQualifier;

    const ageAtSurgery = calculateAge(surgeryDate, dateOfBirth);
    const sex = calculateSex(gender);
    const thoracotomy = calculateThoracotomy(performedAccess);

    const extendedResection = calculateExtendedResection(bilobectomyLobectomyQualifier, pneumonectomyQualifier);
    const pneumonectomy = calculatePneumonectomy(lungExcisionProcedure);

    const predictedMorbidity = calculatePredictedMorbidity(ageAtSurgery, sex, fev1, thoracotomy, extendedResection);
    const predictedMortality = calculatePredictedMortality(ageAtSurgery, sex, bmi, fev1, thoracotomy, pneumonectomy);
    filledData = {
      ageAtSurgery: ageAtSurgery,
      predictedMorbidity: predictedMorbidity,
      predictedMortality: predictedMortality
    };
  }

  const registerStepData = {
    formId: 1004,
    id: 1004,
    name: 'Calculated',
    registerId: 1000,
    stepNumber: 5
  } as RegisterStepData;

  const calculationStep = {
    filledData: filledData,
    registerStepData: registerStepData
  } as CaseStepData;

  currentCase.caseSteps.push(calculationStep);
};

function calculateAge(dateOfSurgery?: Date, dateOfBirth?: string): number | undefined {
  if (dateOfSurgery && dateOfBirth) {
    const surgeryDate = new Date(dateOfSurgery);
    const birthDate = new Date(dateOfBirth);

    let age = surgeryDate.getFullYear() - birthDate.getFullYear();
    const m = surgeryDate.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && surgeryDate.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  return undefined;
}

//female male are reverse in calculations
function calculateSex(gender?: number): number | undefined {
  if (gender != null) {
    if (gender == 0) {
      return SexType.male;
    }
    if (gender == 1) {
      return SexType.female;
    }
  }

  return undefined;
}

function calculateThoracotomy(performedAccess?: number): number | undefined {
  if (performedAccess != null) {
    if (performedAccess == thoracotomySelection) {
      return 1;
    } else {
      return 0;
    }
  }

  return undefined;
}

function calculateExtendedResection(bilobectomyLobectomyQualifier?: number, pneumonectomyQualifier?: number): number | undefined {
  if (bilobectomyLobectomyQualifier != null) {
    if (bilobectomyLobectomyQualifier == bilobectomyLobectomyQualifierSelection) {
      return 1;
    }
  }
  if (pneumonectomyQualifier != null) {
    if (pneumonectomyQualifier in pneumonectomyQualifierSelections) {
      return 1;
    }
  }

  return 0;
}
function calculatePneumonectomy(lungExcisionProcedure?: number): number | undefined {
  if (lungExcisionProcedure != null) {
    if (lungExcisionProcedure == pneumonectomySelection) {
      return 1;
    } else {
      return 0;
    }
  }

  return undefined;
}

function inverseLogit(x) {
  return 1 / (1 + Math.exp(-x));
}

function calculatePredictedMorbidity(age?: number, sex?: number, fev1?: number, thoracotomy?: number, extended_resection?: number) {
  if (age != null && sex != null && fev1 != null && thoracotomy != null && extended_resection != null) {
    const predictedMorbidity = inverseLogit(-2.852 + 0.021 * age + 0.472 * sex - 0.015 * fev1 + 0.662 * thoracotomy + 0.324 * extended_resection);
    return predictedMorbidity;
  }

  return null;
}

function calculatePredictedMortality(age?: number, sex?: number, BMI?: number, fev1?: number, thoracotomy?: number, pneumonectomy?: number) {
  if (age != null && sex != null && BMI != null && fev1 != null && thoracotomy != null && pneumonectomy != null) {
    const predictedMortality = inverseLogit(
      -6.35 + 0.047 * age + 0.889 * sex - 0.055 * BMI - 0.01 * fev1 + 0.892 * thoracotomy + 0.983 * pneumonectomy
    );
    return predictedMortality;
  }

  return null;
}

const createBaseStep = async (currentCase?: CaseData, readonly = false) => {
  const baseConfig = customDeepClone(caseBaseDataFormConfig);

  const step = {
    title: 'Base data',
    state: {
      formConfig: baseConfig,
      form: createForm(baseConfig).element,
      hideRequiredBtn: true,
      status: CaseStepStatus.EMPTY
    }
  } as CaseStep<CaseStepState>;

  step.state.form.value = currentCase;

  await calculateStepStatus(step);

  if (readonly) {
    cleanForm(step.state.form);
  }

  return step;
};

const createCaseStep = async (caseStepData: CaseStepData, readonly: boolean) => {
  const caseStepConfig = customDeepClone(LUNG_REGISTER_STEPS[caseStepData.registerStepData.stepNumber - 1]);

  const stepForm = createForm(caseStepConfig.formConfig).element;
  stepForm.value = caseStepData.filledData;

  if (caseStepConfig.formConfig.settings && caseStepConfig.formConfig.settings.autoInitialize === false) {
    stepForm.initialize();
  }

  const step = {
    title: caseStepConfig.title,
    state: {
      hideRequiredBtn: false,
      requiredState: false,
      formConfig: caseStepConfig.formConfig,
      form: stepForm,
      registerStepData: caseStepData.registerStepData,
      status: CaseStepStatus.EMPTY
    }
  } as CaseStep<CaseStepState>;

  await calculateStepStatus(step);

  if (readonly) {
    cleanForm(step.state.form);
  }

  return step;
};

const cleanFormConfig = (config: FormElementConfig) => {
  if (!config.settings) {
    config.settings = {};
  }

  config.settings.readonly = true;
  config.validators = [];
  config.filters = [];
  config.plugins = [];

  config.children?.forEach((childConfig) => cleanFormConfig(childConfig));
};

const cleanForm = (element: FormElement) => {
  element.readonly = true;
  element.validatorChain.clearValidators();
  element.clearFilters();
  element.clearPlugins();

  element.children?.forEach((child) => cleanForm(child));
};

export const switchRequiredValidators = (element: FormElement, enabled: boolean) => {
  const requiredValidator = element.getValidator('required');

  if (requiredValidator) {
    requiredValidator.enabled = enabled;
  }

  element.children.forEach((child) => switchRequiredValidators(child, enabled));
};

export const calculateStepStatus = async (step: CaseStep<CaseStepState>) => {
  if (!step.state.form) return;

  const form = step.state.form;
  await form.validate({ skipMessages: true });

  const allFields = form.generateSubmitData();
  const requiredFields = getRequiredFields(form);

  let numRequiredFilledAndValid = 0;

  requiredFields.forEach((field) => {
    const validatedField = allFields.validation.data[field];
    const splitName = field.split('.');
    splitName.shift();

    let fieldValue: any = undefined;
    if (splitName.length > 1) {
      splitName.forEach((part) => {
        if (fieldValue === undefined) {
          fieldValue = allFields.values[part];
        } else {
          fieldValue = fieldValue[part];
        }
      });
    } else {
      fieldValue = allFields.values[splitName[0]];
    }

    if (validatedField && validatedField.valid && !isEmpty(fieldValue)) {
      numRequiredFilledAndValid++;
    }
  });

  if (numRequiredFilledAndValid >= requiredFields.length) {
    step.state.status = CaseStepStatus.FILLED;
  } else if (numRequiredFilledAndValid > 0) {
    step.state.status = CaseStepStatus.STARTED;
  } else {
    step.state.status = CaseStepStatus.EMPTY;
  }
};

const getRequiredFields = (element: FormElement) => {
  let required = [] as string[];
  if (element.children.length) {
    element.children.forEach((child) => {
      required = [...required, ...getRequiredFields(child)];
    });

    return required;
  }

  if (element.validatorChain.hasValidator('required')) {
    required = [...required, element.fullName];
  }

  return required;
};

export const registerPlugins = () => {
  formService.registerPlugin('basicCalculatedPlugin', BasicCalculatedPlugin);
  formService.registerPlugin('changeVisibilityPlugin', ChangeVisibilityPlugin);
  formService.registerPlugin('initVisibilityPlugin', InitVisibilityPlugin);
};

export const registerValidators = () => {
  formService.registerValidator('dateIsBetween', DateIsBetweenValidator);
  formService.registerValidator('numberIsBetween', NumberIsBetweenValidator);
  formService.registerValidator('checkboxLength', CheckboxLengthValidator);
};

export const initCaseForms = () => {
  registerPlugins();
  registerValidators();
};

export const getSelectableElement = (item: FormHierarchyItem, form: Form): SelectableFormElement => {
  return getElement(item, form) as SelectableFormElement;
};

export const getElement = (item: FormHierarchyItem, form: Form): FormElement => {
  if (item.parents) {
    return getParent(form, item.parents, 0)?.getChild(item.name);
  } else {
    return form.getChild(item.name) as FormElement;
  }
};

const getParent = (current: any, parents: string[], idx: number): any => {
  if (parents.length <= idx) {
    return current;
  }

  const next = current.getChild(parents[idx]);
  return getParent(next, parents, idx + 1);
};
