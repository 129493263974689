import {
  type FormConfig,
  type RadioGroupConfig,
  type SelectConfig,
  type TextareaConfig,
  type NumberConfig,
  type ComboboxConfig,
  HtmlLabel,
  type FormGroupConfig
} from '@common/index';
import type { BasicCalculatedPluginSettings } from '../../plugins';
import { Fev1lDivFvclCalculation, PpodlcoPercCalculation, Ppofev1PercentCalculation } from '../../utils';
import type { NumberIsBetweenValidatorSettings } from '../../validators';

export const preOpStepFormConfig: FormConfig = {
  name: 'Pre Op',
  plugins: [
    {
      plugin: 'progress'
    }
  ],
  attributes: {
    color: 'primary',
    variant: 'outlined',
    density: 'compact'
  },
  settings: {
    validation: {
      onInput: false
    }
  },
  children: [
    {
      name: 'performanceData',
      component: 'formGroup',
      attributes: {
        label: 'REGISTER.1000.TEXT.PERFORMANCE',
        hideDetails: true
      },
      sheetAttributes: {
        border: false
      },
      settings: {
        needTranslate: true,
        labelComponent: HtmlLabel
      },
      children: [
        {
          name: 'asa',
          component: 'select',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: {
              cols: 12,
              md: 6
            },
            items: [
              {
                label: 'REGISTER.1000.ANSWER.ASA.0',
                value: 0
              },
              {
                label: 'REGISTER.1000.ANSWER.ASA.1',
                value: 1
              },
              {
                label: 'REGISTER.1000.ANSWER.ASA.2',
                value: 2
              },
              {
                label: 'REGISTER.1000.ANSWER.ASA.3',
                value: 3
              },
              {
                label: 'REGISTER.1000.ANSWER.ASA.4',
                value: 4
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.ASA',
            itemTitle: 'label'
          },
          validators: []
        } as SelectConfig,
        {
          name: 'mrcscore',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: { cols: 12, md: 6 },
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.MRCSCORE.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.MRCSCORE.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.MRCSCORE.2',
                value: 2
              },
              {
                title: 'REGISTER.1000.ANSWER.MRCSCORE.3',
                value: 3
              },
              {
                title: 'REGISTER.1000.ANSWER.MRCSCORE.4',
                value: 4
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.MRCSCORE'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'ecog',
          component: 'select',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: {
              cols: 12,
              md: 6
            },
            items: [
              {
                label: 'REGISTER.1000.ANSWER.ECOG.0',
                value: 0
              },
              {
                label: 'REGISTER.1000.ANSWER.ECOG.1',
                value: 1
              },
              {
                label: 'REGISTER.1000.ANSWER.ECOG.2',
                value: 2
              },
              {
                label: 'REGISTER.1000.ANSWER.ECOG.3',
                value: 3
              },
              {
                label: 'REGISTER.1000.ANSWER.ECOG.4',
                value: 4
              },
              {
                label: 'REGISTER.1000.ANSWER.ECOG.5',
                value: 5
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.ECOG',
            itemTitle: 'label'
          },
          validators: []
        } as SelectConfig
      ]
    } as FormGroupConfig,
    {
      name: 'performanceData',
      component: 'formGroup',
      attributes: {
        label: 'REGISTER.1000.TEXT.COMORBIDITY',
        hideDetails: true
      },
      sheetAttributes: {
        border: false,
        class: 'mb-8'
      },
      settings: {
        needTranslate: true,
        labelComponent: HtmlLabel
      },
      children: [
        {
          name: 'cardiaccoMorbidity',
          component: 'combobox',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: { cols: 12, md: 6 },
            items: [
              {
                title: 'REGISTER.1000.ANSWER.CARDIACCOMORBIDITY.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.CARDIACCOMORBIDITY.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.CARDIACCOMORBIDITY.2',
                value: 2
              },
              {
                title: 'REGISTER.1000.ANSWER.CARDIACCOMORBIDITY.3',
                value: 3
              },
              {
                title: 'REGISTER.1000.ANSWER.CARDIACCOMORBIDITY.4',
                value: 4
              },
              {
                title: 'REGISTER.1000.ANSWER.CARDIACCOMORBIDITY.5',
                value: 5
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.CARDIACCOMORBIDITY',
            multiple: true,
            chips: true,
            closableChips: true
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as ComboboxConfig,
        {
          name: 'othercoMorbidities',
          component: 'combobox',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: { cols: 12, md: 6 },
            items: [
              {
                title: 'REGISTER.1000.ANSWER.OTHERCOMORBIDITIES.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.OTHERCOMORBIDITIES.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.OTHERCOMORBIDITIES.2',
                value: 2
              },
              {
                title: 'REGISTER.1000.ANSWER.OTHERCOMORBIDITIES.3',
                value: 3
              },
              {
                title: 'REGISTER.1000.ANSWER.OTHERCOMORBIDITIES.4',
                value: 4
              },
              {
                title: 'REGISTER.1000.ANSWER.OTHERCOMORBIDITIES.5',
                value: 5
              },
              {
                title: 'REGISTER.1000.ANSWER.OTHERCOMORBIDITIES.6',
                value: 6
              },
              {
                title: 'REGISTER.1000.ANSWER.OTHERCOMORBIDITIES.7',
                value: 7
              },
              {
                title: 'REGISTER.1000.ANSWER.OTHERCOMORBIDITIES.8',
                value: 8
              },
              {
                title: 'REGISTER.1000.ANSWER.OTHERCOMORBIDITIES.9',
                value: 9
              },
              {
                title: 'REGISTER.1000.ANSWER.OTHERCOMORBIDITIES.10',
                value: 10
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.OTHERCOMORBIDITIES',
            multiple: true,
            chips: true,
            closableChips: true
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as ComboboxConfig
      ]
    } as FormGroupConfig,

    {
      name: 'urgency',
      component: 'radioGroup',
      settings: {
        needTranslate: true,
        needItemTranslate: true,
        grid: { cols: 12 },
        direction: 'inline',
        items: [
          {
            title: 'REGISTER.1000.ANSWER.URGENCY.0',
            value: 0
          },
          {
            title: 'REGISTER.1000.ANSWER.URGENCY.1',
            value: 1
          },
          {
            title: 'REGISTER.1000.ANSWER.URGENCY.2',
            value: 2
          }
        ]
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.URGENCY'
      },
      validators: []
    } as RadioGroupConfig,

    {
      name: 'segments',
      component: 'formGroup',
      attributes: {
        label: 'REGISTER.1000.TEXT.SEGMENTS'
      },
      sheetAttributes: {
        border: true,
        class: 'mx-4 pa-4 rounded-xl'
      },
      settings: {
        needTranslate: true,
        labelComponent: HtmlLabel,
        grid: { cols: 12, lg: 6 }
      },
      children: [
        {
          name: 'previousSurgerySegmentsRemoved',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: { cols: 12, md: 6 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PREVIOUSSURGERYSEGMENTSREMOVED',
            type: 'number',
            step: 0.01
          },
          validators: [
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 20,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            }
          ]
        } as NumberConfig,
        {
          name: 'functioningSegmentsResected',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: { cols: 12, md: 6 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.FUNCTIONINGSEGMENTSRESECTED',
            type: 'number',
            step: 0.01
          },
          validators: [
            {
              validator: 'required'
            },
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 20,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            }
          ],
          plugins: [
            {
              plugin: 'basicCalculatedPlugin',
              settings: {
                calculations: () => [new PpodlcoPercCalculation(), new Ppofev1PercentCalculation()]
              } as BasicCalculatedPluginSettings
            }
          ]
        } as NumberConfig,
        {
          name: 'atelectaticSegmentsAtOperation',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: { cols: 12, md: 4 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.ATELECTATICSEGMENTSATOPERATION',
            type: 'number',
            step: 0.01
          },
          validators: [
            {
              validator: 'required'
            },
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 20,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            }
          ],
          plugins: [
            {
              plugin: 'basicCalculatedPlugin',
              settings: {
                calculations: () => [new Ppofev1PercentCalculation()]
              } as BasicCalculatedPluginSettings
            }
          ]
        } as NumberConfig,
        {
          name: 'atelectaticSegmentsResected',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: { cols: 12, md: 4 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.ATELECTATICSEGMENTSRESECTED',
            type: 'number',
            step: 0.01
          },
          validators: [
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 20,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            }
          ]
        } as NumberConfig,
        {
          name: 'atelectaticSegmentsRestoredByPro',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: { cols: 12, md: 4 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.ATELECTATICSEGMENTSRESTOREDBYPRO',
            type: 'number',
            step: 0.01
          },
          validators: [
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 20,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            }
          ]
        } as NumberConfig
      ]
    } as FormGroupConfig,
    {
      name: 'spirometry',
      component: 'formGroup',
      attributes: {
        label: 'REGISTER.1000.TEXT.SPIROMETRY'
      },
      sheetAttributes: {
        border: true,
        class: 'mx-4 pa-4 rounded-xl'
      },
      settings: {
        needTranslate: true,
        labelComponent: HtmlLabel,
        grid: { cols: 12, lg: 6 }
      },
      children: [
        {
          name: 'fev1Litres',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: { cols: 12, md: 4 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.FEV1LITRES',
            type: 'number',
            step: 0.01,
            suffix: 'l (0,00)'
          },
          validators: [
            {
              validator: 'required'
            },
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 5,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            }
          ],
          plugins: [
            {
              plugin: 'basicCalculatedPlugin',
              settings: {
                calculations: () => [new Fev1lDivFvclCalculation(), new Ppofev1PercentCalculation()]
              } as BasicCalculatedPluginSettings
            }
          ]
        } as NumberConfig,
        {
          name: 'fev1Percent',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: { cols: 12, md: 4 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.FEV1PERCENT',
            type: 'number',
            step: 0.01,
            suffix: '%'
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as NumberConfig,
        {
          name: 'fvcLitres',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: { cols: 12, md: 4 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.FVCLITRES',
            type: 'number',
            step: 0.01,
            suffix: 'l'
          },
          validators: [
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 8,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            }
          ],
          plugins: [
            {
              plugin: 'basicCalculatedPlugin',
              settings: {
                calculations: () => [new Fev1lDivFvclCalculation()]
              } as BasicCalculatedPluginSettings
            }
          ]
        } as NumberConfig,
        {
          name: 'ppofev1Percent',
          component: 'text',
          settings: {
            needTranslate: true,
            readonly: true,
            grid: { cols: 12, md: 4 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PPOFEV1PERCENT',
            type: 'number',
            step: 0.01,
            suffix: '%'
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as NumberConfig,
        {
          name: 'fvcPerce',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: { cols: 12, md: 4 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.FVCPERCE',
            type: 'number',
            step: 0.01,
            suffix: '%'
          },
          validators: []
        } as NumberConfig,
        {
          name: 'fev1ldivfvcl',
          component: 'text',
          settings: {
            needTranslate: true,
            readonly: true,
            grid: { cols: 12, md: 4 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.FEV1LDIVFVCL',
            type: 'number',
            step: 0.01
          },
          validators: []
        } as NumberConfig,
        {
          name: 'dlcoPercent',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: { cols: 12, md: 4 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.DLCOPERCENT',
            type: 'number',
            step: 0.01,
            suffix: '%'
          },
          validators: [
            {
              validator: 'required'
            }
          ],
          plugins: [
            {
              plugin: 'basicCalculatedPlugin',
              settings: {
                calculations: () => [new PpodlcoPercCalculation()]
              } as BasicCalculatedPluginSettings
            }
          ]
        } as NumberConfig,
        {
          name: 'ppodlcoperc',
          component: 'text',
          settings: {
            needTranslate: true,
            readonly: true,
            grid: { cols: 12, md: 4 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PPODLCOPERC',
            type: 'number',
            step: 0.01,
            suffix: '%'
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as NumberConfig,
        {
          name: 'vo2Max',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: { cols: 12, md: 4 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.VO2MAX',
            type: 'number',
            step: 0.01,
            suffix: 'ml / kg / min'
          },
          validators: [
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 100,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            }
          ]
        } as NumberConfig
      ]
    } as FormGroupConfig,
    {
      name: 'staging',
      component: 'formGroup',
      attributes: {
        label: 'REGISTER.1000.TEXT.STAGING'
      },
      sheetAttributes: {
        border: true,
        class: 'mx-4 pa-4 rounded-xl'
      },
      settings: {
        needTranslate: true,
        labelComponent: HtmlLabel
      },
      children: [
        {
          name: 'morphology',
          component: 'select',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: {
              cols: 12,
              md: 4
            },
            items: [
              {
                label: 'REGISTER.1000.ANSWER.MORPHOLOGY.0',
                value: 0
              },
              {
                label: 'REGISTER.1000.ANSWER.MORPHOLOGY.1',
                value: 1
              },
              {
                label: 'REGISTER.1000.ANSWER.MORPHOLOGY.2',
                value: 2
              },
              {
                label: 'REGISTER.1000.ANSWER.MORPHOLOGY.3',
                value: 3
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.MORPHOLOGY',
            itemTitle: 'label'
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as SelectConfig,
        {
          name: 'ctNodes',
          component: 'select', //radioGroup
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: { cols: 12, md: 4 },
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.CTNODES.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.CTNODES.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.CTNODES.2',
                value: 2
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.CTNODES'
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as RadioGroupConfig,
        {
          name: 'petScan',
          component: 'select', //radioGroup
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: { cols: 12, md: 4 },
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.PETSCAN.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.PETSCAN.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.PETSCAN.2',
                value: 2
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PETSCAN'
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as RadioGroupConfig,
        {
          name: 'preOperativeInvasiveMediastinalStaging',
          component: 'select', //radioGroup
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: { cols: 12, md: 4 },
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.PREOPERATIVEINVASIVEMEDIASTINALSTAGING.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.PREOPERATIVEINVASIVEMEDIASTINALSTAGING.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.PREOPERATIVEINVASIVEMEDIASTINALSTAGING.2',
                value: 2
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PREOPERATIVEINVASIVEMEDIASTINALSTAGING'
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as SelectConfig, //RadioGroupConfig

        {
          name: 'neoadjuvant',
          component: 'select', //radioGroup
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.NEOADJUVANT.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.NEOADJUVANT.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.NEOADJUVANT.2',
                value: 2
              },
              {
                title: 'REGISTER.1000.ANSWER.NEOADJUVANT.3',
                value: 3
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.NEOADJUVANT'
          },
          validators: []
        } as SelectConfig, //RadioGroupConfig
        {
          name: 'nsclcSubGroup',
          component: 'select',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: {
              cols: 12,
              md: 6
            },
            items: [
              {
                label: 'REGISTER.1000.ANSWER.NSCLCSUBGROUP.0',
                value: 0
              },
              {
                label: 'REGISTER.1000.ANSWER.NSCLCSUBGROUP.1',
                value: 1
              },
              {
                label: 'REGISTER.1000.ANSWER.NSCLCSUBGROUP.2',
                value: 2
              },
              {
                label: 'REGISTER.1000.ANSWER.NSCLCSUBGROUP.3',
                value: 3
              },
              {
                label: 'REGISTER.1000.ANSWER.NSCLCSUBGROUP.4',
                value: 4
              },
              {
                label: 'REGISTER.1000.ANSWER.NSCLCSUBGROUP.5',
                value: 5
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.NSCLCSUBGROUP',
            itemTitle: 'label'
          },
          validators: []
        } as SelectConfig,
        {
          name: 'euroendocrineSubGroup',
          component: 'select',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: { cols: 12, md: 6 },
            items: [
              {
                title: 'REGISTER.1000.ANSWER.EUROENDOCRINESUBGROUP.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.EUROENDOCRINESUBGROUP.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.EUROENDOCRINESUBGROUP.2',
                value: 2
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.EUROENDOCRINESUBGROUP'
          },
          validators: []
        } as SelectConfig,
        {
          name: 'diameterOfLesionCm',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: 12,
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.DIAMETEROFLESIONCM',
            type: 'number',
            step: 0.01,
            suffix: 'cm'
          },
          validators: [
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 50,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            }
          ]
        } as NumberConfig,
        {
          name: 'primerTumorTNM',
          component: 'formGroup',
          attributes: {
            label: 'REGISTER.1000.TEXT.PRIMER_TUMOR_TNM'
          },
          sheetAttributes: {
            border: true,
            class: 'mx-4 pa-4 rounded-xl'
          },
          settings: {
            needTranslate: true,
            labelComponent: HtmlLabel
          },
          children: [
            {
              name: 'clinicalTNM',
              component: 'formGroup',
              attributes: {
                label: 'REGISTER.1000.TEXT.CLINICAL'
              },
              sheetAttributes: {
                border: true,
                class: 'mx-4 pa-4 rounded-xl'
              },
              settings: {
                needTranslate: true,
                labelComponent: HtmlLabel,
                grid: { cols: 12, md: 6 }
              },
              children: [
                {
                  name: 'ct',
                  component: 'radioGroup',
                  settings: {
                    needTranslate: true,
                    needItemTranslate: true,
                    grid: {
                      cols: 12
                    },
                    direction: 'inline',
                    items: [
                      {
                        label: 'REGISTER.1000.ANSWER.CT.0',
                        value: 0
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CT.1',
                        value: 1
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CT.2',
                        value: 2
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CT.3',
                        value: 3
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CT.4',
                        value: 4
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CT.5',
                        value: 5
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CT.6',
                        value: 6
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CT.7',
                        value: 7
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CT.8',
                        value: 8
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CT.9',
                        value: 9
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CT.10',
                        value: 10
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CT.11',
                        value: 11
                      }
                    ]
                  },
                  attributes: {
                    label: 'REGISTER.1000.TEXT.CT',
                    itemTitle: 'label'
                  },
                  validators: [
                    {
                      validator: 'required'
                    }
                  ]
                } as RadioGroupConfig,
                {
                  name: 'cn',
                  component: 'radioGroup',
                  settings: {
                    needTranslate: true,
                    needItemTranslate: true,
                    grid: { cols: 12 },
                    direction: 'inline',
                    items: [
                      {
                        title: 'REGISTER.1000.ANSWER.CN.0',
                        value: 0
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CN.1',
                        value: 1
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CN.2',
                        value: 2
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CN.3',
                        value: 3
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CN.4',
                        value: 4
                      }
                    ]
                  },
                  attributes: {
                    label: 'REGISTER.1000.TEXT.CN'
                  },
                  validators: [
                    {
                      validator: 'required'
                    }
                  ]
                } as RadioGroupConfig,
                {
                  name: 'cm',
                  component: 'radioGroup',
                  settings: {
                    needTranslate: true,
                    needItemTranslate: true,
                    grid: { cols: 12 },
                    direction: 'inline',
                    items: [
                      {
                        label: 'REGISTER.1000.ANSWER.CM.0',
                        value: 0
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CM.1',
                        value: 1
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CM.2',
                        value: 2
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CM.3',
                        value: 3
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CM.4',
                        value: 4
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.CM.5',
                        value: 5
                      }
                    ]
                  },
                  attributes: {
                    label: 'REGISTER.1000.TEXT.CM',
                    itemTitle: 'label'
                  },
                  validators: [
                    {
                      validator: 'required'
                    }
                  ]
                } as RadioGroupConfig
              ]
            } as FormGroupConfig
          ]
        } as FormGroupConfig,
        {
          name: 'oesophagusTNM',
          component: 'formGroup',
          attributes: {
            label: 'REGISTER.1000.TEXT.OESOPHAGUS'
          },
          sheetAttributes: {
            border: true,
            class: 'mx-4 pa-4 rounded-xl'
          },
          settings: {
            needTranslate: true,
            labelComponent: HtmlLabel
          },
          children: [
            {
              name: 'oesophagusClinicalTNM',
              component: 'formGroup',
              attributes: {
                label: 'REGISTER.1000.TEXT.CLINICAL'
              },
              sheetAttributes: {
                border: true,
                class: 'mx-4 pa-4 rounded-xl'
              },
              settings: {
                needTranslate: true,
                labelComponent: HtmlLabel,
                grid: { cols: 12, md: 6 }
              },
              children: [
                {
                  name: 'ctOesophagus',
                  component: 'radioGroup',
                  settings: {
                    needTranslate: true,
                    needItemTranslate: true,
                    grid: { cols: 12 },
                    direction: 'inline',
                    items: [
                      {
                        title: 'REGISTER.1000.ANSWER.CTOESOPHAGUS.0',
                        value: 0
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CTOESOPHAGUS.1',
                        value: 1
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CTOESOPHAGUS.2',
                        value: 2
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CTOESOPHAGUS.3',
                        value: 3
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CTOESOPHAGUS.4',
                        value: 4
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CTOESOPHAGUS.5',
                        value: 5
                      }
                    ]
                  },
                  attributes: {
                    label: 'REGISTER.1000.TEXT.CTOESOPHAGUS'
                  },
                  validators: []
                } as RadioGroupConfig,
                {
                  name: 'cmOesophagus',
                  component: 'radioGroup',
                  settings: {
                    needTranslate: true,
                    needItemTranslate: true,
                    grid: { cols: 12 },
                    direction: 'inline',
                    items: [
                      {
                        title: 'REGISTER.1000.ANSWER.CMOESOPHAGUS.0',
                        value: 0
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CMOESOPHAGUS.1',
                        value: 1
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CMOESOPHAGUS.2',
                        value: 2
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CMOESOPHAGUS.3',
                        value: 3
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CMOESOPHAGUS.4',
                        value: 4
                      }
                    ]
                  },
                  attributes: {
                    label: 'REGISTER.1000.TEXT.CMOESOPHAGUS'
                  },
                  validators: []
                } as RadioGroupConfig,
                {
                  name: 'cnOesophagus',
                  component: 'radioGroup',
                  settings: {
                    needTranslate: true,
                    needItemTranslate: true,
                    grid: { cols: 12 },
                    direction: 'inline',
                    items: [
                      {
                        title: 'REGISTER.1000.ANSWER.CNOESOPHAGUS.0',
                        value: 0
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CNOESOPHAGUS.1',
                        value: 1
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CNOESOPHAGUS.2',
                        value: 2
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.CNOESOPHAGUS.3',
                        value: 3
                      }
                    ]
                  },
                  attributes: {
                    label: 'REGISTER.1000.TEXT.CNOESOPHAGUS'
                  },
                  validators: []
                } as RadioGroupConfig
              ]
            } as FormGroupConfig
          ]
        } as FormGroupConfig
      ]
    } as FormGroupConfig,
    {
      name: 'diagnosisData',
      component: 'formGroup',
      attributes: {
        label: 'REGISTER.1000.TEXT.DIAGNOSIS_DATA'
      },
      sheetAttributes: {
        border: true,
        class: 'mx-4 pa-4 rounded-xl'
      },
      settings: {
        needTranslate: true,
        labelComponent: HtmlLabel,
        grid: { cols: 12 }
      },
      children: [
        {
          name: 'diagnosis',
          component: 'select',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: { cols: 12, lg: 6 },
            items: [
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.0',
                value: 0
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.1',
                value: 1
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.2',
                value: 2
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.3',
                value: 3
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.4',
                value: 4
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.5',
                value: 5
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.6',
                value: 6
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.7',
                value: 7
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.8',
                value: 8
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.9',
                value: 9
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.10',
                value: 10
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.11',
                value: 11
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.12',
                value: 12
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.13',
                value: 13
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.14',
                value: 14
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.15',
                value: 15
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.16',
                value: 16
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.17',
                value: 17
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.18',
                value: 18
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.19',
                value: 19
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.20',
                value: 20
              },
              {
                label: 'REGISTER.1000.ANSWER.DIAGNOSIS.21',
                value: 21
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.DIAGNOSIS',
            itemTitle: 'label'
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as SelectConfig,
        {
          name: 'otherDiagnosis',
          component: 'textarea',
          settings: {
            needTranslate: true,
            grid: { cols: 12, lg: 6 }
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.OTHERDIAGNOSIS',
            counter: 500
          },
          validators: [
            {
              validator: 'length',
              settings: {
                min: 0,
                max: 500
              }
            }
          ]
        } as TextareaConfig
      ]
    } as FormGroupConfig
  ]
};
