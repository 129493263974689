import { createI18n as i18nCreate, useI18n as i18nUse, type UseI18nOptions, type I18n } from 'vue-i18n';

let i18n: I18n<any, any, any, string, false>;

export function createI18n(locale: string, messages: any, fallbackLocale = 'en') {
  i18n = i18nCreate({
    legacy: false,
    locale,
    fallbackLocale,
    messages,
    numberFormats: {
      hu: {
        currency: {
          style: 'currency',
          currency: 'HUF',
          maximumFractionDigits: 0
        }
      }
    },
    datetimeFormats: {
      en: {
        short: {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }
      }
    },
    warnHtmlMessage: false
  });

  return i18n;
}

export const useI18nInstance = () => i18n;

export const useI18n = (options?: UseI18nOptions) => i18nUse(options);
