import { FormValidator, isEmpty, type FormValidatorConfig, type FormValidatorSettings } from '@common/index';

export interface NumberIsBetweenValidatorSettings extends FormValidatorSettings {
  numberFrom: number;
  numberTo: number;
  allowEqual: boolean;
}

export type NumberIsBetweenValidatorConfig = FormValidatorConfig<NumberIsBetweenValidatorSettings>;

export class NumberIsBetweenValidator extends FormValidator<NumberIsBetweenValidatorSettings> {
  constructor() {
    super();
    this._name = 'numberIsBetween';
    this._settings = {
      invalidMessage: 'form.validator.numberIsBetween.invalidMessage',
      needTranslate: true,
      numberFrom: 0,
      numberTo: 20,
      allowEqual: true
    };
  }

  public override async onValidate(value: any) {
    if (isEmpty(value)) {
      this.reset();
      return;
    }

    this._valid = this.settings.allowEqual
      ? value <= this.settings.numberTo && value >= this.settings.numberFrom
      : value > this.settings.numberFrom && value < this.settings.numberTo;

    if (!this._valid) {
      this.addMessage(this.settings.invalidMessage, 'error', { numberFrom: this.settings.numberFrom, numberTo: this.settings.numberTo });
    }
  }
}
