import type { RouteRecordRaw } from 'vue-router';
import core from './modules/core/router/routes';
import authRoutes from '@common/lib/modules/auth/routes/routes';
import caseRoutes from './modules/case/routes/routes';
import adminRoutes from './modules/admin/routes/routes';
import analyticsRoutes from './modules/analytics/routes/routes';
import dashboard from './modules/dashboard/routes/routes';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    children: [
      {
        path: '',
        component: () => import('./components/layouts/MainLayout.vue'),
        children: [...core, ...analyticsRoutes, ...dashboard, ...caseRoutes, ...adminRoutes, ...authRoutes.mainRoutes]
      },
      {
        path: '',
        component: () => import('./components/layouts/LoginLayout.vue'),
        children: [...authRoutes.routes]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('./modules/core/pages/ErrorPage.vue')
  }
];

export default routes;
