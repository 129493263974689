import {
  type FormConfig,
  type RadioGroupConfig,
  type SelectConfig,
  type TextConfig,
  type TextareaConfig,
  type ComboboxConfig,
  type NumberConfig,
  type FormGroupConfig,
  HtmlLabel,
  FormElement
} from '@common/index';
import type { NumberIsBetweenValidatorSettings } from '../../validators';
import type { FormHierarchyItem } from '../../models';
import type { ChangeVisibilityPluginConfig, InitVisibilityPluginConfig } from '../../plugins';
import { getElement } from '../../utils';

export const opStepFormConfig: FormConfig = {
  name: 'Op',
  plugins: [
    {
      plugin: 'progress'
    }
  ],
  attributes: {
    color: 'primary',
    variant: 'outlined',
    density: 'compact'
  },
  settings: {
    validation: {
      onInput: false
    },
    autoInitialize: false
  },
  children: [
    {
      name: 'plannedProcedures',
      component: 'formGroup',
      attributes: {
        label: 'REGISTER.1000.TEXT.PLANNED_PROCEDURES',
        hideDetails: true
      },
      sheetAttributes: {
        // border: true,
        class: 'py-4' //'mx-4 pa-4 rounded-xl'
      },
      settings: {
        needTranslate: true,
        labelComponent: HtmlLabel,
        grid: { cols: 12 }
      },
      children: [
        {
          name: 'plannedLungExcisionProcedure',
          component: 'select',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: {
              cols: 12,
              md: 6
            },
            items: [
              {
                label: 'REGISTER.1000.ANSWER.PLANNEDLUNGEXCISIONPROCEDURE.0',
                value: 0
              },
              {
                label: 'REGISTER.1000.ANSWER.PLANNEDLUNGEXCISIONPROCEDURE.1',
                value: 1
              },
              {
                label: 'REGISTER.1000.ANSWER.PLANNEDLUNGEXCISIONPROCEDURE.2',
                value: 2
              },
              {
                label: 'REGISTER.1000.ANSWER.PLANNEDLUNGEXCISIONPROCEDURE.3',
                value: 3
              },
              {
                label: 'REGISTER.1000.ANSWER.PLANNEDLUNGEXCISIONPROCEDURE.4',
                value: 4
              },
              {
                label: 'REGISTER.1000.ANSWER.PLANNEDLUNGEXCISIONPROCEDURE.5',
                value: 5
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PLANNEDLUNGEXCISIONPROCEDURE',
            itemTitle: 'label'
          },
          validators: []
        } as SelectConfig,
        {
          name: 'plannedAccess',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            grid: {
              cols: 12,
              md: 6
            },
            items: [
              {
                title: 'REGISTER.1000.ANSWER.PLANNEDACCESS.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.PLANNEDACCESS.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.PLANNEDACCESS.2',
                value: 2
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PLANNEDACCESS'
          },
          validators: []
        } as RadioGroupConfig
      ]
    } as FormGroupConfig,
    {
      name: 'performedProcedures',
      component: 'formGroup',
      attributes: {
        label: 'REGISTER.1000.TEXT.PREFORMED_PROCEDURES',
        hideDetails: true
      },
      sheetAttributes: {
        // border: true,
        class: 'py-4' //'mx-4 pa-4 rounded-xl'
      },
      settings: {
        needTranslate: true,
        labelComponent: HtmlLabel,
        grid: { cols: 12 }
      },
      children: [
        {
          name: 'groupDefinition',
          component: 'select',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: {
              cols: 12,
              md: 6
            },
            items: [
              {
                label: 'REGISTER.1000.ANSWER.GROUPDEFINITION.0',
                value: 0
              },
              {
                label: 'REGISTER.1000.ANSWER.GROUPDEFINITION.1',
                value: 1
              },
              {
                label: 'REGISTER.1000.ANSWER.GROUPDEFINITION.2',
                value: 2
              },
              {
                label: 'REGISTER.1000.ANSWER.GROUPDEFINITION.3',
                value: 3
              },
              {
                label: 'REGISTER.1000.ANSWER.GROUPDEFINITION.4',
                value: 4
              },
              {
                label: 'REGISTER.1000.ANSWER.GROUPDEFINITION.5',
                value: 5
              },
              {
                label: 'REGISTER.1000.ANSWER.GROUPDEFINITION.6',
                value: 6
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.GROUPDEFINITION',
            itemTitle: 'label'
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as SelectConfig,
        {
          name: 'groupOtherProcedure',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: {
              cols: 12,
              md: 6
            }
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.GROUPOTHERPROCEDURE'
          },
          validators: []
        } as TextConfig,
        {
          name: 'performedAccess',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.PERFORMEDACCESS.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.PERFORMEDACCESS.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.PERFORMEDACCESS.2',
                value: 2
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PERFORMEDACCESS'
          },
          validators: [{ validator: 'required' }],
          plugins: [
            {
              plugin: 'changeVisibilityPlugin',
              settings: {
                targetFields: [
                  {
                    formHierarchyItem: { name: 'roboticData', parents: [] },
                    setVisibility: (targetElement, value) => {
                      const visible = !!value && value === 2;

                      if (!visible) {
                        targetElement.children.forEach((child) => (child.value = null));
                      }
                      targetElement.visible = visible;
                    }
                  }
                ]
              }
            } as ChangeVisibilityPluginConfig
          ]
        } as RadioGroupConfig,
        {
          name: 'reasonConversion',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.REASONCONVERSION.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.REASONCONVERSION.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.REASONCONVERSION.2',
                value: 2
              },
              {
                title: 'REGISTER.1000.ANSWER.REASONCONVERSION.3',
                value: 3
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.REASONCONVERSION'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'reasonotherresection',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.REASONOTHERRESECTION.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.REASONOTHERRESECTION.1',
                value: 1
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.REASONOTHERRESECTION'
          },
          validators: []
        } as RadioGroupConfig,

        {
          name: 'lungSubgroupData',
          component: 'formGroup',
          attributes: {
            label: 'REGISTER.1000.TEXT.LUNG_SUBGROUP_DATA',
            hideDetails: true
          },
          sheetAttributes: {
            border: true,
            class: 'ma-4 pa-4 rounded-xl'
          },
          settings: {
            needTranslate: true,
            labelComponent: HtmlLabel,
            grid: { cols: 12 }
          },
          children: [
            {
              name: 'lungSubGroup',
              component: 'select',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                grid: {
                  cols: 12,
                  md: 6
                },
                items: [
                  {
                    label: 'REGISTER.1000.ANSWER.LUNGSUBGROUP.0',
                    value: 0
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.LUNGSUBGROUP.1',
                    value: 1
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.LUNGSUBGROUP.2',
                    value: 2
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.LUNGSUBGROUP.3',
                    value: 3
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.LUNGSUBGROUP.4',
                    value: 4
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.LUNGSUBGROUP',
                itemTitle: 'label'
              },
              validators: [
                {
                  validator: 'required'
                }
              ]
            } as SelectConfig,
            {
              name: 'lungExcisionProcedure',
              component: 'select',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                grid: {
                  cols: 12,
                  md: 6
                },
                items: [
                  {
                    label: 'REGISTER.1000.ANSWER.LUNGEXCISIONPROCEDURE.0',
                    value: 0
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.LUNGEXCISIONPROCEDURE.1',
                    value: 1
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.LUNGEXCISIONPROCEDURE.2',
                    value: 2
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.LUNGEXCISIONPROCEDURE.3',
                    value: 3
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.LUNGEXCISIONPROCEDURE.4',
                    value: 4
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.LUNGEXCISIONPROCEDURE.5',
                    value: 5
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.LUNGEXCISIONPROCEDURE',
                itemTitle: 'label'
              },
              validators: [
                {
                  validator: 'required'
                }
              ]
            } as SelectConfig,
            {
              name: 'vats',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.VATS.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.VATS.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.VATS'
              },
              validators: [
                {
                  validator: 'required'
                }
              ]
            } as RadioGroupConfig,
            {
              name: 'bilobectomyGroup',
              component: 'formGroup',
              attributes: {
                label: 'REGISTER.1000.TEXT.BILOBECTOMY_DATA',
                hideDetails: true
              },
              sheetAttributes: {
                border: true,
                class: 'mx-4 pa-4 rounded-xl'
              },
              settings: {
                needTranslate: true,
                labelComponent: HtmlLabel,
                grid: { cols: 12, md: 6 }
              },
              children: [
                {
                  name: 'bilobectomyLobectomyQualifier',
                  component: 'select',
                  settings: {
                    needTranslate: true,
                    needItemTranslate: true,
                    grid: {
                      cols: 12
                    },
                    items: [
                      {
                        label: 'REGISTER.1000.ANSWER.BILOBECTOMYLOBECTOMYQUALIFIER.0',
                        value: 0
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.BILOBECTOMYLOBECTOMYQUALIFIER.1',
                        value: 1
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.BILOBECTOMYLOBECTOMYQUALIFIER.2',
                        value: 2
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.BILOBECTOMYLOBECTOMYQUALIFIER.3',
                        value: 3
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.BILOBECTOMYLOBECTOMYQUALIFIER.4',
                        value: 4
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.BILOBECTOMYLOBECTOMYQUALIFIER.5',
                        value: 5
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.BILOBECTOMYLOBECTOMYQUALIFIER.6',
                        value: 6
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.BILOBECTOMYLOBECTOMYQUALIFIER.7',
                        value: 7
                      }
                    ]
                  },
                  attributes: {
                    label: 'REGISTER.1000.TEXT.BILOBECTOMYLOBECTOMYQUALIFIER',
                    itemTitle: 'label'
                  },
                  validators: [
                    {
                      validator: 'required'
                    }
                  ]
                } as SelectConfig,
                {
                  name: 'bilobectomyProcSite',
                  component: 'radioGroup',
                  settings: {
                    needTranslate: true,
                    needItemTranslate: true,
                    direction: 'inline',
                    items: [
                      {
                        title: 'REGISTER.1000.ANSWER.BILOBECTOMYPROCSITE.0',
                        value: 0
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.BILOBECTOMYPROCSITE.1',
                        value: 1
                      }
                    ]
                  },
                  attributes: {
                    label: 'REGISTER.1000.TEXT.BILOBECTOMYPROCSITE'
                  },
                  validators: []
                } as RadioGroupConfig
              ]
            } as FormGroupConfig,
            {
              name: 'pneumonectomyGroup',
              component: 'formGroup',
              attributes: {
                label: 'REGISTER.1000.TEXT.PNEUMONECTOMY_DATA',
                hideDetails: true
              },
              sheetAttributes: {
                border: true,
                class: 'mx-4 pa-4 rounded-xl'
              },
              settings: {
                needTranslate: true,
                labelComponent: HtmlLabel,
                grid: { cols: 12, md: 6 }
              },
              children: [
                {
                  name: 'pneumonectomyQualifier',
                  component: 'select',
                  settings: {
                    needTranslate: true,
                    needItemTranslate: true,
                    grid: {
                      cols: 12
                    },
                    items: [
                      {
                        label: 'REGISTER.1000.ANSWER.PNEUMONECTOMYQUALIFIER.0',
                        value: 0
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.PNEUMONECTOMYQUALIFIER.1',
                        value: 1
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.PNEUMONECTOMYQUALIFIER.2',
                        value: 2
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.PNEUMONECTOMYQUALIFIER.3',
                        value: 3
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.PNEUMONECTOMYQUALIFIER.4',
                        value: 4
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.PNEUMONECTOMYQUALIFIER.5',
                        value: 5
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.PNEUMONECTOMYQUALIFIER.6',
                        value: 6
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.PNEUMONECTOMYQUALIFIER.7',
                        value: 7
                      },
                      {
                        label: 'REGISTER.1000.ANSWER.PNEUMONECTOMYQUALIFIER.8',
                        value: 8
                      }
                    ]
                  },
                  attributes: {
                    label: 'REGISTER.1000.TEXT.PNEUMONECTOMYQUALIFIER',
                    itemTitle: 'label'
                  },
                  validators: []
                } as SelectConfig,
                {
                  name: 'pneumonectomySide',
                  component: 'radioGroup',
                  settings: {
                    needTranslate: true,
                    needItemTranslate: true,
                    direction: 'inline',
                    items: [
                      {
                        title: 'REGISTER.1000.ANSWER.PNEUMONECTOMYSIDE.0',
                        value: 0
                      },
                      {
                        title: 'REGISTER.1000.ANSWER.PNEUMONECTOMYSIDE.1',
                        value: 1
                      }
                    ]
                  },
                  attributes: {
                    label: 'REGISTER.1000.TEXT.PNEUMONECTOMYSIDE'
                  },
                  validators: []
                } as RadioGroupConfig
              ]
            } as FormGroupConfig,
            {
              name: 'lungVolumeReductionQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.LUNGVOLUMEREDUCTIONQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.LUNGVOLUMEREDUCTIONQUALIFIER.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.LUNGVOLUMEREDUCTIONQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'wedgeQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.WEDGEQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.WEDGEQUALIFIER.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.WEDGEQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'subgrouplunglesionProcedure',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.SUBGROUPLUNGLESIONPROCEDURE.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.SUBGROUPLUNGLESIONPROCEDURE.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.SUBGROUPLUNGLESIONPROCEDURE.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.SUBGROUPLUNGLESIONPROCEDURE.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.SUBGROUPLUNGLESIONPROCEDURE'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'lobectomyProcSite',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.LOBECTOMYPROCSITE.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.LOBECTOMYPROCSITE.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.LOBECTOMYPROCSITE.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.LOBECTOMYPROCSITE.3',
                    value: 3
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.LOBECTOMYPROCSITE.4',
                    value: 4
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.LOBECTOMYPROCSITE'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'lungRepairProcedures',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.LUNGREPAIRPROCEDURES.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.LUNGREPAIRPROCEDURES.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.LUNGREPAIRPROCEDURES.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.LUNGREPAIRPROCEDURES.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.LUNGREPAIRPROCEDURES'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'repairBullaOfLungQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.REPAIRBULLAOFLUNGQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.REPAIRBULLAOFLUNGQUALIFIER.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.REPAIRBULLAOFLUNGQUALIFIER.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.REPAIRBULLAOFLUNGQUALIFIER.3',
                    value: 3
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.REPAIRBULLAOFLUNGQUALIFIER.4',
                    value: 4
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.REPAIRBULLAOFLUNGQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'qualifierForRepairOfLung',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORREPAIROFLUNG.0',
                    value: 0
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.QUALIFIERFORREPAIROFLUNG'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'subgroupLungTransplantProcedures',
              component: 'select',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                grid: {
                  cols: 12,
                  md: 6
                },
                items: [
                  {
                    label: 'REGISTER.1000.ANSWER.SUBGROUPLUNGTRANSPLANTPROCEDURES.0',
                    value: 0
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.SUBGROUPLUNGTRANSPLANTPROCEDURES.1',
                    value: 1
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.SUBGROUPLUNGTRANSPLANTPROCEDURES.2',
                    value: 2
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.SUBGROUPLUNGTRANSPLANTPROCEDURES.3',
                    value: 3
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.SUBGROUPLUNGTRANSPLANTPROCEDURES.4',
                    value: 4
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.SUBGROUPLUNGTRANSPLANTPROCEDURES',
                itemTitle: 'label'
              },
              validators: []
            } as SelectConfig
          ]
        } as FormGroupConfig,
        {
          name: 'pleuraSubgroupData',
          component: 'formGroup',
          attributes: {
            label: 'REGISTER.1000.TEXT.PLEURA_SUBGROUP_DATA',
            hideDetails: true
          },
          sheetAttributes: {
            border: true,
            class: 'ma-4 pa-4 rounded-xl'
          },
          settings: {
            needTranslate: true,
            labelComponent: HtmlLabel,
            grid: { cols: 12 }
          },
          children: [
            {
              name: 'pleuraSubgroup',
              component: 'select',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                grid: {
                  cols: 12,
                  md: 6
                },
                items: [
                  {
                    label: 'REGISTER.1000.ANSWER.PLEURASUBGROUP.0',
                    value: 0
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.PLEURASUBGROUP.1',
                    value: 1
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.PLEURASUBGROUP.2',
                    value: 2
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.PLEURASUBGROUP.3',
                    value: 3
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.PLEURASUBGROUP.4',
                    value: 4
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.PLEURASUBGROUP.5',
                    value: 5
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.PLEURASUBGROUP.6',
                    value: 6
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.PLEURASUBGROUP',
                itemTitle: 'label'
              },
              validators: []
            } as SelectConfig,
            {
              name: 'pleuralBiopsyProcedure',
              component: 'select',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                grid: {
                  cols: 12,
                  md: 6
                },
                items: [
                  {
                    label: 'REGISTER.1000.ANSWER.PLEURALBIOPSYPROCEDURE.0',
                    value: 0
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.PLEURALBIOPSYPROCEDURE.1',
                    value: 1
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.PLEURALBIOPSYPROCEDURE.2',
                    value: 2
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.PLEURALBIOPSYPROCEDURE.3',
                    value: 3
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.PLEURALBIOPSYPROCEDURE.4',
                    value: 4
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.PLEURALBIOPSYPROCEDURE',
                itemTitle: 'label'
              },
              validators: []
            } as SelectConfig,
            {
              name: 'pleurectomyPleurodesisOld',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.PLEURECTOMYPLEURODESISOLD.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.PLEURECTOMYPLEURODESISOLD.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.PLEURECTOMYPLEURODESISOLD.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.PLEURECTOMYPLEURODESISOLD.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.PLEURECTOMYPLEURODESISOLD'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'qualifierForPleuralCatheterProcedure',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORPLEURALCATHETERPROCEDURE.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORPLEURALCATHETERPROCEDURE.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORPLEURALCATHETERPROCEDURE.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.QUALIFIERFORPLEURALCATHETERPROCEDURE'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'qualifierForPleurectomyProcedure',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORPLEURECTOMYPROCEDURE.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORPLEURECTOMYPROCEDURE.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORPLEURECTOMYPROCEDURE.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.QUALIFIERFORPLEURECTOMYPROCEDURE'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'pleurectomyPleurodesis',
              component: 'checkboxGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.PLEURECTOMYPLEURODESIS.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.PLEURECTOMYPLEURODESIS.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.PLEURECTOMYPLEURODESIS.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.PLEURECTOMYPLEURODESIS',
                multiple: true
              },
              validators: []
            } as ComboboxConfig,
            {
              name: 'thoracoscopyProcedure',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.THORACOSCOPYPROCEDURE.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.THORACOSCOPYPROCEDURE.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.THORACOSCOPYPROCEDURE.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.THORACOSCOPYPROCEDURE.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.THORACOSCOPYPROCEDURE'
              },
              validators: []
            } as RadioGroupConfig
          ]
        } as FormGroupConfig,
        {
          name: 'chestWallSubgroupData',
          component: 'formGroup',
          attributes: {
            label: 'REGISTER.1000.TEXT.CHEST_WALL_SUBGROUP_DATA',
            hideDetails: true
          },
          sheetAttributes: {
            border: true,
            class: 'ma-4 pa-4 rounded-xl'
          },
          settings: {
            needTranslate: true,
            labelComponent: HtmlLabel,
            grid: { cols: 12 }
          },
          children: [
            {
              name: 'chestWallSubgroup',
              component: 'select',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                grid: {
                  cols: 12,
                  md: 6
                },
                items: [
                  {
                    label: 'REGISTER.1000.ANSWER.CHESTWALLSUBGROUP.0',
                    value: 0
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CHESTWALLSUBGROUP.1',
                    value: 1
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CHESTWALLSUBGROUP.2',
                    value: 2
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CHESTWALLSUBGROUP.3',
                    value: 3
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CHESTWALLSUBGROUP.4',
                    value: 4
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CHESTWALLSUBGROUP.5',
                    value: 5
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.CHESTWALLSUBGROUP',
                itemTitle: 'label'
              },
              validators: []
            } as SelectConfig,
            {
              name: 'chestWallProcedures',
              component: 'select',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                grid: {
                  cols: 12,
                  md: 6
                },
                items: [
                  {
                    label: 'REGISTER.1000.ANSWER.CHESTWALLPROCEDURES.0',
                    value: 0
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CHESTWALLPROCEDURES.1',
                    value: 1
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CHESTWALLPROCEDURES.2',
                    value: 2
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CHESTWALLPROCEDURES.3',
                    value: 3
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CHESTWALLPROCEDURES.4',
                    value: 4
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CHESTWALLPROCEDURES.5',
                    value: 5
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.CHESTWALLPROCEDURES',
                itemTitle: 'label'
              },
              validators: []
            } as SelectConfig,
            {
              name: 'qualifierExcisionOfChestWallLesion',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIEREXCISIONOFCHESTWALLLESION.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIEREXCISIONOFCHESTWALLLESION.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIEREXCISIONOFCHESTWALLLESION.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.QUALIFIEREXCISIONOFCHESTWALLLESION'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'qualifierForRepairOfChestWall',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORREPAIROFCHESTWALL.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORREPAIROFCHESTWALL.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORREPAIROFCHESTWALL.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.QUALIFIERFORREPAIROFCHESTWALL'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'excisionRepairQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.EXCISIONREPAIRQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.EXCISIONREPAIRQUALIFIER.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.EXCISIONREPAIRQUALIFIER.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.EXCISIONREPAIRQUALIFIER.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.EXCISIONREPAIRQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'costalCartilageProcedure',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.COSTALCARTILAGEPROCEDURE.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.COSTALCARTILAGEPROCEDURE.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.COSTALCARTILAGEPROCEDURE.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.COSTALCARTILAGEPROCEDURE'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'chestWallIncisionProcedures',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.CHESTWALLINCISIONPROCEDURES.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.CHESTWALLINCISIONPROCEDURES.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.CHESTWALLINCISIONPROCEDURES.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.CHESTWALLINCISIONPROCEDURES.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.CHESTWALLINCISIONPROCEDURES'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'correctionOfChestWallDefectsQualifier',
              component: 'select',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                grid: {
                  cols: 12,
                  md: 6
                },
                items: [
                  {
                    label: 'REGISTER.1000.ANSWER.CORRECTIONOFCHESTWALLDEFECTSQUALIFIER.0',
                    value: 0
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CORRECTIONOFCHESTWALLDEFECTSQUALIFIER.1',
                    value: 1
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CORRECTIONOFCHESTWALLDEFECTSQUALIFIER.2',
                    value: 2
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CORRECTIONOFCHESTWALLDEFECTSQUALIFIER.3',
                    value: 3
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CORRECTIONOFCHESTWALLDEFECTSQUALIFIER.4',
                    value: 4
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CORRECTIONOFCHESTWALLDEFECTSQUALIFIER.5',
                    value: 5
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.CORRECTIONOFCHESTWALLDEFECTSQUALIFIER.6',
                    value: 6
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.CORRECTIONOFCHESTWALLDEFECTSQUALIFIER',
                itemTitle: 'label'
              },
              validators: []
            } as SelectConfig,
            {
              name: 'ribProcedures',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.RIBPROCEDURES.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.RIBPROCEDURES.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.RIBPROCEDURES'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'qualifierForRibresection',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORRIBRESECTION.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORRIBRESECTION.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORRIBRESECTION.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.QUALIFIERFORRIBRESECTION'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'thoracoplastyProcedures',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.THORACOPLASTYPROCEDURES.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.THORACOPLASTYPROCEDURES.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.THORACOPLASTYPROCEDURES'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'qualifierForPlombage',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORPLOMBAGE.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORPLOMBAGE.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.QUALIFIERFORPLOMBAGE'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'qualifierForThoracoplasty',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORTHORACOPLASTY.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORTHORACOPLASTY.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORTHORACOPLASTY.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.QUALIFIERFORTHORACOPLASTY'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'costalCartilageProcedures',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.COSTALCARTILAGEPROCEDURES.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.COSTALCARTILAGEPROCEDURES.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.COSTALCARTILAGEPROCEDURES.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.COSTALCARTILAGEPROCEDURES'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'qualifierPectusCarenatum',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERPECTUSCARENATUM.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERPECTUSCARENATUM.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.QUALIFIERPECTUSCARENATUM'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'prevchestwallIncQualifier',
              component: 'checkboxGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.PREVCHESTWALLINCQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.PREVCHESTWALLINCQUALIFIER.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.PREVCHESTWALLINCQUALIFIER.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.PREVCHESTWALLINCQUALIFIER.3',
                    value: 3
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.PREVCHESTWALLINCQUALIFIER.4',
                    value: 4
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.PREVCHESTWALLINCQUALIFIER.5',
                    value: 5
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.PREVCHESTWALLINCQUALIFIER',
                multiple: true
              },
              validators: []
            } as ComboboxConfig
          ]
        } as FormGroupConfig,
        {
          name: 'tracheBronchusSubgroupData',
          component: 'formGroup',
          attributes: {
            label: 'REGISTER.1000.TEXT.TRACHEA_BRONCHUS_SUBGROUP_DATA',
            hideDetails: true
          },
          sheetAttributes: {
            border: true,
            class: 'ma-4 pa-4 rounded-xl'
          },
          settings: {
            needTranslate: true,
            labelComponent: HtmlLabel,
            grid: { cols: 12 }
          },
          children: [
            {
              name: 'tracheaBronchusSubgroup',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.TRACHEABRONCHUSSUBGROUP.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.TRACHEABRONCHUSSUBGROUP.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.TRACHEABRONCHUSSUBGROUP.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.TRACHEABRONCHUSSUBGROUP.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.TRACHEABRONCHUSSUBGROUP'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'bronchoscopicStentProcedures',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.BRONCHOSCOPICSTENTPROCEDURES.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.BRONCHOSCOPICSTENTPROCEDURES.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.BRONCHOSCOPICSTENTPROCEDURES.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.BRONCHOSCOPICSTENTPROCEDURES.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.BRONCHOSCOPICSTENTPROCEDURES'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'chgOrPlacOrRemovQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.CHGORPLACORREMOVQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.CHGORPLACORREMOVQUALIFIER.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.CHGORPLACORREMOVQUALIFIER.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.CHGORPLACORREMOVQUALIFIER.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.CHGORPLACORREMOVQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'carinaProcedure',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.CARINAPROCEDURE.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.CARINAPROCEDURE.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.CARINAPROCEDURE.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.CARINAPROCEDURE.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.CARINAPROCEDURE'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'bronchusProcedures',
              component: 'select',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                grid: {
                  cols: 12,
                  md: 6
                },
                items: [
                  {
                    label: 'REGISTER.1000.ANSWER.BRONCHUSPROCEDURES.0',
                    value: 0
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.BRONCHUSPROCEDURES.1',
                    value: 1
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.BRONCHUSPROCEDURES.2',
                    value: 2
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.BRONCHUSPROCEDURES.3',
                    value: 3
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.BRONCHUSPROCEDURES.4',
                    value: 4
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.BRONCHUSPROCEDURES.5',
                    value: 5
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.BRONCHUSPROCEDURES',
                itemTitle: 'label'
              },
              validators: []
            } as SelectConfig,
            {
              name: 'tracheaProcedure',
              component: 'select',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                grid: {
                  cols: 12,
                  md: 6
                },
                items: [
                  {
                    label: 'REGISTER.1000.ANSWER.TRACHEAPROCEDURE.0',
                    value: 0
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.TRACHEAPROCEDURE.1',
                    value: 1
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.TRACHEAPROCEDURE.2',
                    value: 2
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.TRACHEAPROCEDURE.3',
                    value: 3
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.TRACHEAPROCEDURE.4',
                    value: 4
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.TRACHEAPROCEDURE.5',
                    value: 5
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.TRACHEAPROCEDURE.6',
                    value: 6
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.TRACHEAPROCEDURE',
                itemTitle: 'label'
              },
              validators: []
            } as SelectConfig,
            {
              name: 'qualifierForTrachealReconstructi',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORTRACHEALRECONSTRUCTI.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORTRACHEALRECONSTRUCTI.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.QUALIFIERFORTRACHEALRECONSTRUCTI'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'qualifierForTracheostomy',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORTRACHEOSTOMY.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORTRACHEOSTOMY.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERFORTRACHEOSTOMY.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.QUALIFIERFORTRACHEOSTOMY'
              },
              validators: []
            } as RadioGroupConfig
          ]
        } as FormGroupConfig,
        {
          name: 'mediastinumSubgroupData',
          component: 'formGroup',
          attributes: {
            label: 'REGISTER.1000.TEXT.MEDIASTINUM_SUBGROUP_DATA',
            hideDetails: true
          },
          sheetAttributes: {
            border: true,
            class: 'ma-4 pa-4 rounded-xl'
          },
          settings: {
            needTranslate: true,
            labelComponent: HtmlLabel,
            grid: { cols: 12 }
          },
          children: [
            {
              name: 'mediastinumSubgroup',
              component: 'select',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                grid: {
                  cols: 12,
                  md: 6
                },
                items: [
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUP.0',
                    value: 0
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUP.1',
                    value: 1
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUP.2',
                    value: 2
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUP.3',
                    value: 3
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUP.4',
                    value: 4
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.MEDIASTINUMSUBGROUP',
                itemTitle: 'label'
              },
              validators: []
            } as SelectConfig,
            {
              name: 'mediastinoscopyProcedures',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.MEDIASTINOSCOPYPROCEDURES.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.MEDIASTINOSCOPYPROCEDURES.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.MEDIASTINOSCOPYPROCEDURES.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.MEDIASTINOSCOPYPROCEDURES.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.MEDIASTINOSCOPYPROCEDURES'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'anteriorMediastinotomyProcedureQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.ANTERIORMEDIASTINOTOMYPROCEDUREQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.ANTERIORMEDIASTINOTOMYPROCEDUREQUALIFIER.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.ANTERIORMEDIASTINOTOMYPROCEDUREQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'mediastinumSubgroupProcedures',
              component: 'select',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                grid: {
                  cols: 12,
                  md: 6
                },
                items: [
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUPPROCEDURES.0',
                    value: 0
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUPPROCEDURES.1',
                    value: 1
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUPPROCEDURES.2',
                    value: 2
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUPPROCEDURES.3',
                    value: 3
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUPPROCEDURES.4',
                    value: 4
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUPPROCEDURES.5',
                    value: 5
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUPPROCEDURES.6',
                    value: 6
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUPPROCEDURES.7',
                    value: 7
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUPPROCEDURES.8',
                    value: 8
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUPPROCEDURES.9',
                    value: 9
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.MEDIASTINUMSUBGROUPPROCEDURES.10',
                    value: 10
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.MEDIASTINUMSUBGROUPPROCEDURES',
                itemTitle: 'label'
              },
              validators: []
            } as SelectConfig,
            {
              name: 'pericardialWindowQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.PERICARDIALWINDOWQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.PERICARDIALWINDOWQUALIFIER.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.PERICARDIALWINDOWQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'thymectomyQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.THYMECTOMYQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.THYMECTOMYQUALIFIER.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.THYMECTOMYQUALIFIER.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.THYMECTOMYQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'ligationOfThoracicDuctProcedure',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.LIGATIONOFTHORACICDUCTPROCEDURE.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.LIGATIONOFTHORACICDUCTPROCEDURE.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.LIGATIONOFTHORACICDUCTPROCEDURE'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'thyroidProcedures',
              component: 'select',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                grid: {
                  cols: 12,
                  md: 6
                },
                items: [
                  {
                    label: 'REGISTER.1000.ANSWER.THYROIDPROCEDURES.0',
                    value: 0
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.THYROIDPROCEDURES.1',
                    value: 1
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.THYROIDPROCEDURES.2',
                    value: 2
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.THYROIDPROCEDURES.3',
                    value: 3
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.THYROIDPROCEDURES.4',
                    value: 4
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.THYROIDPROCEDURES.5',
                    value: 5
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.THYROIDPROCEDURES.6',
                    value: 6
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.THYROIDPROCEDURES.7',
                    value: 7
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.THYROIDPROCEDURES',
                itemTitle: 'label'
              },
              validators: []
            } as SelectConfig,
            {
              name: 'qualifierThLobThyroidecPartial',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERTHLOBTHYROIDECPARTIAL.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERTHLOBTHYROIDECPARTIAL.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.QUALIFIERTHLOBTHYROIDECPARTIAL.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.QUALIFIERTHLOBTHYROIDECPARTIAL'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'thyroidectomySubtotalTotal',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.THYROIDECTOMYSUBTOTALTOTAL.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.THYROIDECTOMYSUBTOTALTOTAL.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.THYROIDECTOMYSUBTOTALTOTAL'
              },
              validators: []
            } as RadioGroupConfig
          ]
        } as FormGroupConfig,
        {
          name: 'upperGiSubgroupData',
          component: 'formGroup',
          attributes: {
            label: 'REGISTER.1000.TEXT.UPPER_GI_SUBGROUP_DATA',
            hideDetails: true
          },
          sheetAttributes: {
            border: true,
            class: 'ma-4 pa-4 rounded-xl'
          },
          settings: {
            needTranslate: true,
            labelComponent: HtmlLabel,
            grid: { cols: 12 }
          },
          children: [
            {
              name: 'upperGiSubgroup',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.UPPERGISUBGROUP.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.UPPERGISUBGROUP.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.UPPERGISUBGROUP.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.UPPERGISUBGROUP.3',
                    value: 3
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.UPPERGISUBGROUP.4',
                    value: 4
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.UPPERGISUBGROUP'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'minimallyInvasiveProcedure',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.MINIMALLYINVASIVEPROCEDURE.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.MINIMALLYINVASIVEPROCEDURE.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.MINIMALLYINVASIVEPROCEDURE'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'antirefluxProcedures',
              component: 'select',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                grid: {
                  cols: 12,
                  md: 6
                },
                items: [
                  {
                    label: 'REGISTER.1000.ANSWER.ANTIREFLUXPROCEDURES.0',
                    value: 0
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.ANTIREFLUXPROCEDURES.1',
                    value: 1
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.ANTIREFLUXPROCEDURES.2',
                    value: 2
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.ANTIREFLUXPROCEDURES.3',
                    value: 3
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.ANTIREFLUXPROCEDURES.4',
                    value: 4
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.ANTIREFLUXPROCEDURES.5',
                    value: 5
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.ANTIREFLUXPROCEDURES',
                itemTitle: 'label'
              },
              validators: []
            } as SelectConfig,
            {
              name: 'gastropexyQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.GASTROPEXYQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.GASTROPEXYQUALIFIER.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.GASTROPEXYQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'gastroplastyLongitudinalQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.GASTROPLASTYLONGITUDINALQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.GASTROPLASTYLONGITUDINALQUALIFIER.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.GASTROPLASTYLONGITUDINALQUALIFIER.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.GASTROPLASTYLONGITUDINALQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'partialFundoplicationQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.PARTIALFUNDOPLICATIONQUALIFIER.0',
                    value: 0
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.PARTIALFUNDOPLICATIONQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'gastricSubgroupProcedures',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.GASTRICSUBGROUPPROCEDURES.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.GASTRICSUBGROUPPROCEDURES.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.GASTRICSUBGROUPPROCEDURES.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.GASTRICSUBGROUPPROCEDURES.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.GASTRICSUBGROUPPROCEDURES'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'pyloroplastyQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.PYLOROPLASTYQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.PYLOROPLASTYQUALIFIER.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.PYLOROPLASTYQUALIFIER.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.PYLOROPLASTYQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'totalGastrectomyQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.TOTALGASTRECTOMYQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.TOTALGASTRECTOMYQUALIFIER.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.TOTALGASTRECTOMYQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'oesophagectomyProcedures',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.OESOPHAGECTOMYPROCEDURES.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.OESOPHAGECTOMYPROCEDURES.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.OESOPHAGECTOMYPROCEDURES.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.OESOPHAGECTOMYPROCEDURES.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.OESOPHAGECTOMYPROCEDURES'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'partialOesophagectomyQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.PARTIALOESOPHAGECTOMYQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.PARTIALOESOPHAGECTOMYQUALIFIER.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.PARTIALOESOPHAGECTOMYQUALIFIER.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.PARTIALOESOPHAGECTOMYQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'subtotalEosophagectomyQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.SUBTOTALEOSOPHAGECTOMYQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.SUBTOTALEOSOPHAGECTOMYQUALIFIER.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.SUBTOTALEOSOPHAGECTOMYQUALIFIER.2',
                    value: 2
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.SUBTOTALEOSOPHAGECTOMYQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'totalOesophagectomyQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.TOTALOESOPHAGECTOMYQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.TOTALOESOPHAGECTOMYQUALIFIER.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.TOTALOESOPHAGECTOMYQUALIFIER.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.TOTALOESOPHAGECTOMYQUALIFIER.3',
                    value: 3
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.TOTALOESOPHAGECTOMYQUALIFIER.4',
                    value: 4
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.TOTALOESOPHAGECTOMYQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'oesophagusExcludingResectionProcedures',
              component: 'select',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                grid: {
                  cols: 12,
                  md: 6
                },
                items: [
                  {
                    label: 'REGISTER.1000.ANSWER.OESOPHAGUSEXCLUDINGRESECTIONPROCEDURES.0',
                    value: 0
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.OESOPHAGUSEXCLUDINGRESECTIONPROCEDURES.1',
                    value: 1
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.OESOPHAGUSEXCLUDINGRESECTIONPROCEDURES.2',
                    value: 2
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.OESOPHAGUSEXCLUDINGRESECTIONPROCEDURES.3',
                    value: 3
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.OESOPHAGUSEXCLUDINGRESECTIONPROCEDURES.4',
                    value: 4
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.OESOPHAGUSEXCLUDINGRESECTIONPROCEDURES.5',
                    value: 5
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.OESOPHAGUSEXCLUDINGRESECTIONPROCEDURES.6',
                    value: 6
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.OESOPHAGUSEXCLUDINGRESECTIONPROCEDURES.7',
                    value: 7
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.OESOPHAGUSEXCLUDINGRESECTIONPROCEDURES.8',
                    value: 8
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.OESOPHAGUSEXCLUDINGRESECTIONPROCEDURES.9',
                    value: 9
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.OESOPHAGUSEXCLUDINGRESECTIONPROCEDURES.10',
                    value: 10
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.OESOPHAGUSEXCLUDINGRESECTIONPROCEDURES.11',
                    value: 11
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.OESOPHAGUSEXCLUDINGRESECTIONPROCEDURES.12',
                    value: 12
                  },
                  {
                    label: 'REGISTER.1000.ANSWER.OESOPHAGUSEXCLUDINGRESECTIONPROCEDURES.13',
                    value: 13
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.OESOPHAGUSEXCLUDINGRESECTIONPROCEDURES',
                itemTitle: 'label'
              },
              validators: []
            } as SelectConfig,
            {
              name: 'bypassOfOesophagusQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.BYPASSOFOESOPHAGUSQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.BYPASSOFOESOPHAGUSQUALIFIER.1',
                    value: 1
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.BYPASSOFOESOPHAGUSQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'cardiomyotomyQualifier',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.CARDIOMYOTOMYQUALIFIER.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.CARDIOMYOTOMYQUALIFIER.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.CARDIOMYOTOMYQUALIFIER.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.CARDIOMYOTOMYQUALIFIER.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.CARDIOMYOTOMYQUALIFIER'
              },
              validators: []
            } as RadioGroupConfig,
            {
              name: 'upperGiOtherProcedure',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.UPPERGIOTHERPROCEDURE.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.UPPERGIOTHERPROCEDURE.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.UPPERGIOTHERPROCEDURE.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.UPPERGIOTHERPROCEDURE.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.UPPERGIOTHERPROCEDURE'
              },
              validators: []
            } as RadioGroupConfig
          ]
        } as FormGroupConfig,
        {
          name: 'diaphragmSubgroupData',
          component: 'formGroup',
          attributes: {
            label: 'REGISTER.1000.TEXT.DIAPHRAGM_SUBGROUP_DATA',
            hideDetails: true
          },
          sheetAttributes: {
            border: true,
            class: 'ma-4 pa-4 rounded-xl'
          },
          settings: {
            needTranslate: true,
            labelComponent: HtmlLabel,
            grid: { cols: 12 }
          },
          children: [
            {
              name: 'diaphragmGroupProcedures',
              component: 'radioGroup',
              settings: {
                needTranslate: true,
                needItemTranslate: true,
                direction: 'inline',
                items: [
                  {
                    title: 'REGISTER.1000.ANSWER.DIAPHRAGMGROUPPROCEDURES.0',
                    value: 0
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.DIAPHRAGMGROUPPROCEDURES.1',
                    value: 1
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.DIAPHRAGMGROUPPROCEDURES.2',
                    value: 2
                  },
                  {
                    title: 'REGISTER.1000.ANSWER.DIAPHRAGMGROUPPROCEDURES.3',
                    value: 3
                  }
                ]
              },
              attributes: {
                label: 'REGISTER.1000.TEXT.DIAPHRAGMGROUPPROCEDURES'
              },
              validators: []
            } as RadioGroupConfig
          ]
        } as FormGroupConfig
      ]
    } as FormGroupConfig,
    {
      name: 'lymphnodesData',
      component: 'formGroup',
      attributes: {
        label: 'REGISTER.1000.TEXT.LYMPHNODES_DATA',
        hideDetails: true
      },
      sheetAttributes: {
        // border: true,
        class: 'py-4' //'mx-4 pa-4 rounded-xl'
      },
      settings: {
        needTranslate: true,
        labelComponent: HtmlLabel,
        grid: { cols: 12 }
      },
      children: [
        {
          name: 'nilarlymphnode',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: { cols: 12, md: 6 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.NILARLYMPHNODE',
            type: 'number',
            step: 0.01
          },
          validators: [
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 20,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            },
            {
              validator: 'required'
            }
          ]
        } as NumberConfig,
        {
          name: 'nilarlymphnodepos',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: { cols: 12, md: 6 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.NILARLYMPHNODEPOS',
            type: 'number',
            step: 0.01
          },
          validators: [
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 20,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            },
            {
              validator: 'required'
            }
          ]
        } as NumberConfig,
        {
          name: 'nmedlymphnode',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: { cols: 12, md: 6 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.NMEDLYMPHNODE',
            type: 'number',
            step: 0.01
          },
          validators: [
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 20,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            },
            {
              validator: 'required'
            }
          ]
        } as NumberConfig,
        {
          name: 'nmedlymphnodepos',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: { cols: 12, md: 6 },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.NMEDLYMPHNODEPOS',
            type: 'number',
            step: 0.01
          },
          validators: [
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 20,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            },
            {
              validator: 'required'
            }
          ]
        } as NumberConfig,
        {
          name: 'nLympNodStatSampled',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: {
              cols: 12,
              md: 6
            },
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.NLYMPNODSTATSAMPLED',
            type: 'number',
            step: 0.01
          },
          validators: [
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 20,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            },
            {
              validator: 'required'
            }
          ]
        } as NumberConfig,
        {
          name: 'lymphadenectom',
          component: 'select',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: {
              cols: 12,
              md: 6
            },
            items: [
              {
                label: 'REGISTER.1000.ANSWER.LYMPHADENECTOM.0',
                value: 0
              },
              {
                label: 'REGISTER.1000.ANSWER.LYMPHADENECTOM.1',
                value: 1
              },
              {
                label: 'REGISTER.1000.ANSWER.LYMPHADENECTOM.2',
                value: 2
              },
              {
                label: 'REGISTER.1000.ANSWER.LYMPHADENECTOM.3',
                value: 3
              },
              {
                label: 'REGISTER.1000.ANSWER.LYMPHADENECTOM.4',
                value: 4
              },
              {
                label: 'REGISTER.1000.ANSWER.LYMPHADENECTOM.5',
                value: 5
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.LYMPHADENECTOM',
            itemTitle: 'label'
          },
          validators: [
            {
              validator: 'required'
            }
          ]
        } as SelectConfig
      ]
    } as FormGroupConfig,

    {
      name: 'roboticData',
      component: 'formGroup',
      attributes: {
        label: 'REGISTER.1000.TEXT.ROBOTIC_DATA',
        hideDetails: true
      },
      sheetAttributes: {
        // border: true,
        class: 'py-4' //'mx-4 pa-4 rounded-xl'
      },
      settings: {
        needTranslate: true,
        labelComponent: HtmlLabel,
        grid: { cols: 12 },
        hideGridSlotWhenInvisible: true
      },
      plugins: [
        {
          plugin: 'initVisibilityPlugin',
          settings: {
            setVisibility: (element: FormElement) => {
              const form = element.form;
              const sourceElementHierarchyItem: FormHierarchyItem = { name: 'performedAccess', parents: ['performedProcedures'] };
              const sourceElement = getElement(sourceElementHierarchyItem, form);

              element.visible = !!sourceElement.value && sourceElement.value === 2;
            }
          }
        } as InitVisibilityPluginConfig
      ],
      children: [
        {
          name: 'nRoboticArms',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.NROBOTICARMS.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.NROBOTICARMS.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.NROBOTICARMS.2',
                value: 2
              },
              {
                title: 'REGISTER.1000.ANSWER.NROBOTICARMS.3',
                value: 3
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.NROBOTICARMS'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'nIncision',
          component: 'select',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: {
              cols: 12,
              md: 6
            },
            items: [
              {
                label: 'REGISTER.1000.ANSWER.NINCISION.0',
                value: 0
              },
              {
                label: 'REGISTER.1000.ANSWER.NINCISION.1',
                value: 1
              },
              {
                label: 'REGISTER.1000.ANSWER.NINCISION.2',
                value: 2
              },
              {
                label: 'REGISTER.1000.ANSWER.NINCISION.3',
                value: 3
              },
              {
                label: 'REGISTER.1000.ANSWER.NINCISION.4',
                value: 4
              },
              {
                label: 'REGISTER.1000.ANSWER.NINCISION.5',
                value: 5
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.NINCISION',
            itemTitle: 'label'
          },
          validators: []
        } as SelectConfig,
        {
          name: 'modelRoboticSurgery',
          component: 'select',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: {
              cols: 12,
              md: 6
            },
            items: [
              {
                label: 'REGISTER.1000.ANSWER.MODELROBOTICSURGERY.0',
                value: 0
              },
              {
                label: 'REGISTER.1000.ANSWER.MODELROBOTICSURGERY.1',
                value: 1
              },
              {
                label: 'REGISTER.1000.ANSWER.MODELROBOTICSURGERY.2',
                value: 2
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.MODELROBOTICSURGERY',
            itemTitle: 'label'
          },
          validators: []
        } as SelectConfig,
        {
          name: 'sizeutincision',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: 12,
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.SIZEUTINCISION',
            type: 'number',
            step: 0.01,
            suffix: 'cm'
          },
          validators: [
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 10,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            }
          ]
        } as NumberConfig,
        {
          name: 'siteUtilityIncision',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.SITEUTILITYINCISION.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.SITEUTILITYINCISION.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.SITEUTILITYINCISION.2',
                value: 2
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.SITEUTILITYINCISION'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'co2Insufflation',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.CO2INSUFFLATION.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.CO2INSUFFLATION.1',
                value: 1
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.CO2INSUFFLATION'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'opticType',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.OPTICTYPE.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.OPTICTYPE.1',
                value: 1
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.OPTICTYPE'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'stapler',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.STAPLER.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.STAPLER.1',
                value: 1
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.STAPLER'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'nRoboticTools',
          component: 'select',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: {
              cols: 12,
              md: 6
            },
            items: [
              {
                label: 'REGISTER.1000.ANSWER.NROBOTICTOOLS.0',
                value: 0
              },
              {
                label: 'REGISTER.1000.ANSWER.NROBOTICTOOLS.1',
                value: 1
              },
              {
                label: 'REGISTER.1000.ANSWER.NROBOTICTOOLS.2',
                value: 2
              },
              {
                label: 'REGISTER.1000.ANSWER.NROBOTICTOOLS.3',
                value: 3
              },
              {
                label: 'REGISTER.1000.ANSWER.NROBOTICTOOLS.4',
                value: 4
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.NROBOTICTOOLS',
            itemTitle: 'label'
          },
          validators: []
        } as SelectConfig,
        {
          name: 'prefToolDissection',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.PREFTOOLDISSECTION.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.PREFTOOLDISSECTION.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.PREFTOOLDISSECTION.2',
                value: 2
              },
              {
                title: 'REGISTER.1000.ANSWER.PREFTOOLDISSECTION.3',
                value: 3
              },
              {
                title: 'REGISTER.1000.ANSWER.PREFTOOLDISSECTION.4',
                value: 4
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PREFTOOLDISSECTION'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'indiocianinegreen',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.INDIOCIANINEGREEN.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.INDIOCIANINEGREEN.1',
                value: 1
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.INDIOCIANINEGREEN'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'conversionRob',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.CONVERSIONROB.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.CONVERSIONROB.1',
                value: 1
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.CONVERSIONROB'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'causCconversion',
          component: 'select',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: {
              cols: 12,
              md: 6
            },
            items: [
              {
                label: 'REGISTER.1000.ANSWER.CAUSCCONVERSION.0',
                value: 0
              },
              {
                label: 'REGISTER.1000.ANSWER.CAUSCCONVERSION.1',
                value: 1
              },
              {
                label: 'REGISTER.1000.ANSWER.CAUSCCONVERSION.2',
                value: 2
              },
              {
                label: 'REGISTER.1000.ANSWER.CAUSCCONVERSION.3',
                value: 3
              },
              {
                label: 'REGISTER.1000.ANSWER.CAUSCCONVERSION.4',
                value: 4
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.CAUSCCONVERSION',
            itemTitle: 'label'
          },
          validators: []
        } as SelectConfig,
        {
          name: 'intraComplicatRob',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.INTRACOMPLICATROB.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.INTRACOMPLICATROB.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.INTRACOMPLICATROB.2',
                value: 2
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.INTRACOMPLICATROB'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'frozenSection',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.FROZENSECTION.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.FROZENSECTION.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.FROZENSECTION.2',
                value: 2
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.FROZENSECTION'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'pleuralAdhesions',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.PLEURALADHESIONS.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.PLEURALADHESIONS.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.PLEURALADHESIONS.2',
                value: 2
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PLEURALADHESIONS'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'limphnDissectionRob',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.LIMPHNDISSECTIONROB.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.LIMPHNDISSECTIONROB.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.LIMPHNDISSECTIONROB.2',
                value: 2
              },
              {
                title: 'REGISTER.1000.ANSWER.LIMPHNDISSECTIONROB.3',
                value: 3
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.LIMPHNDISSECTIONROB'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'anhestRobotic',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.ANHESTROBOTIC.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.ANHESTROBOTIC.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.ANHESTROBOTIC.2',
                value: 2
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.ANHESTROBOTIC'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'nDrainsRob',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.NDRAINSROB.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.NDRAINSROB.1',
                value: 1
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.NDRAINSROB'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'bloodloss',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.BLOODLOSS.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.BLOODLOSS.1',
                value: 1
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.BLOODLOSS'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'durationSurgeryMin',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: 12,
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.DURATIONSURGERYMIN',
            type: 'number',
            step: 0.01,
            suffix: 'min'
          },
          validators: [
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 480,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            }
          ]
        } as NumberConfig,
        {
          name: 'dockingtimeMin',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: 12,
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.DOCKINGTIMEMIN',
            type: 'number',
            step: 0.01,
            suffix: 'min'
          },
          validators: [
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 90,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            }
          ]
        } as NumberConfig,
        {
          name: 'notesRobSurgery',
          component: 'textarea',
          settings: {
            needTranslate: true,
            grid: 12
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.NOTESROBSURGERY',
            counter: 500
          },
          validators: [
            {
              validator: 'length',
              settings: {
                min: 0,
                max: 500
              }
            }
          ]
        } as TextareaConfig,
        {
          name: 'pathologyRobotic',
          component: 'select',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            grid: {
              cols: 12,
              md: 6
            },
            items: [
              {
                label: 'REGISTER.1000.ANSWER.PATHOLOGYROBOTIC.0',
                value: 0
              },
              {
                label: 'REGISTER.1000.ANSWER.PATHOLOGYROBOTIC.1',
                value: 1
              },
              {
                label: 'REGISTER.1000.ANSWER.PATHOLOGYROBOTIC.2',
                value: 2
              },
              {
                label: 'REGISTER.1000.ANSWER.PATHOLOGYROBOTIC.3',
                value: 3
              },
              {
                label: 'REGISTER.1000.ANSWER.PATHOLOGYROBOTIC.4',
                value: 4
              },
              {
                label: 'REGISTER.1000.ANSWER.PATHOLOGYROBOTIC.5',
                value: 5
              },
              {
                label: 'REGISTER.1000.ANSWER.PATHOLOGYROBOTIC.6',
                value: 6
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.PATHOLOGYROBOTIC',
            itemTitle: 'label'
          },
          validators: []
        } as SelectConfig,
        {
          name: 'typeOfadenocarcinoma',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.TYPEOFADENOCARCINOMA.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.TYPEOFADENOCARCINOMA.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.TYPEOFADENOCARCINOMA.2',
                value: 2
              },
              {
                title: 'REGISTER.1000.ANSWER.TYPEOFADENOCARCINOMA.3',
                value: 3
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.TYPEOFADENOCARCINOMA'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'gradingRobSurgery',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.GRADINGROBSURGERY.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.GRADINGROBSURGERY.1',
                value: 1
              },
              {
                title: 'REGISTER.1000.ANSWER.GRADINGROBSURGERY.2',
                value: 2
              },
              {
                title: 'REGISTER.1000.ANSWER.GRADINGROBSURGERY.3',
                value: 3
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.GRADINGROBSURGERY'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'sizeRobSurgery',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: {
              cols: 12,
              md: 6
            }
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.SIZEROBSURGERY'
          },
          validators: []
        } as TextConfig,
        {
          name: 'otherPrefToolDissection',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: {
              cols: 12,
              md: 6
            }
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.OTHERPREFTOOLDISSECTION'
          },
          validators: []
        } as TextConfig,
        {
          name: 'useAdvEnergy',
          component: 'radioGroup',
          settings: {
            needTranslate: true,
            needItemTranslate: true,
            direction: 'inline',
            items: [
              {
                title: 'REGISTER.1000.ANSWER.USEADVENERGY.0',
                value: 0
              },
              {
                title: 'REGISTER.1000.ANSWER.USEADVENERGY.1',
                value: 1
              }
            ]
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.USEADVENERGY'
          },
          validators: []
        } as RadioGroupConfig,
        {
          name: 'numCartridges',
          component: 'text',
          settings: {
            needTranslate: true,
            grid: 12,
            defaultValue: 0
          },
          attributes: {
            label: 'REGISTER.1000.TEXT.NUMCARTRIDGES',
            type: 'number',
            step: 0.01
          },
          validators: [
            {
              validator: 'numberIsBetween',
              settings: {
                numberFrom: 0,
                numberTo: 20,
                allowEqual: true
              } as NumberIsBetweenValidatorSettings
            }
          ]
        } as NumberConfig
      ]
    } as FormGroupConfig
  ]
};
