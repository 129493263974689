import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/admin/user-management',
    name: 'user-management',
    component: () => import('../pages/UserListPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/context-management/country',
    name: 'country',
    component: () => import('../pages/CountryListPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/admin/context-management/center',
  //   name: 'center',
  //   component: () => import('../pages/CenterListPage.vue'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/admin/context-management/country/:countryId',
    name: 'centers',
    props: true,
    component: () => import('../pages/CenterListPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/context-management/user-context',
    name: 'user-context',
    component: () => import('../pages/UserContextListPage.vue'),
    meta: {
      requiresAuth: true
    }
  }
];

export default routes;
