//import 'vuetify/styles';
import { createVuetify as vuetifyCreate, type ThemeDefinition } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { useI18n } from 'vue-i18n';

import DateFnsAdapter from '@date-io/date-fns';
import hu from 'date-fns/locale/hu';
import en from 'date-fns/locale/en-US';

export function createVuetify(i18n: any, themes: { [key: string]: ThemeDefinition } | null = null, customIcons: any = {}, defaultTheme = 'light') {
  const config = {
    components,
    directives,
    icons: {
      defaultSet: 'mdi',
      aliases: {
        ...aliases,
        ...customIcons
      },
      sets: {
        mdi
      }
    },
    locale: {
      adapter: createVueI18nAdapter({ i18n, useI18n })
    },
    date: {
      adapter: DateFnsAdapter as any,
      locale: {
        hu: hu,
        en: en
      }
    }
  };

  if (themes) {
    config['theme'] = {
      themes,
      defaultTheme: defaultTheme
    };
  }

  return vuetifyCreate(config);
}
