import type { AsyncAutocompleteSettings, AutocompleteConfig, FormConfig, FormElement, SelectConfig, SelectableFormElement } from '../models';

export const isEmpty = (value: any) => {
  return value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
};

export const setSelectableFormElementChildItems = (childName: string, items: any[], parent: FormElement) => {
  const child = parent.getChild(childName) as SelectableFormElement;
  if (!child) {
    console.warn(`[Form Utils] Form element "${childName}" is not in parent ${parent.name}`);
    return;
  }

  child.items = items;
};

export const setAsyncAutocompleteElementAdditionalParam = (childName: string, paramName: string, paramValue, parent: FormElement) => {
  const child = parent.getChild(childName) as SelectableFormElement;

  if (!child) {
    console.warn(`[Form Utils] Form element "${childName}" is not in parent ${parent.name}`);
    return;
  }

  const settings = child.settings as AsyncAutocompleteSettings;
  if (!settings.additionalParams) {
    settings.additionalParams = {};
  }

  settings.additionalParams[paramName] = paramValue;
};

export const setFormElementDefaultValue = (childName: string, value: any, parent: FormElement) => {
  const child = parent.getChild(childName) as FormElement;
  if (!child) {
    console.warn(`[Form Utils] Form element "${childName}" is not in parent ${parent.name}`);
    return;
  }

  child.value = value;
};

export const setFormSelectConfigItems = (fieldName: string, items: any[], formConfig: FormConfig) => {
  const fieldConfig = findFormConfigChildren(fieldName, formConfig) as SelectConfig;

  if (!fieldConfig) {
    console.warn(`[Form Utils] Form element config  "${fieldName}" is not in parent config ${formConfig.name}`);
    return;
  }

  if (fieldConfig.settings) {
    fieldConfig.settings.items = items;
  } else {
    fieldConfig.settings = { items };
  }
};

export const setFormSelectFieldConfigDefaultValue = (fieldName: string, value: any, formConfig: FormConfig) => {
  const fieldConfig = findFormConfigChildren(fieldName, formConfig) as SelectConfig;

  if (!fieldConfig) return;

  if (fieldConfig.settings) {
    fieldConfig.settings.defaultValue = value;
  } else {
    fieldConfig.settings = { defaultValue: value, items: [value] };
  }
};

export const setFormFieldConfigSuffix = (fieldName: string, suffix: string, formConfig: FormConfig) => {
  const fieldConfig = findFormConfigChildren(fieldName, formConfig);
  if (!fieldConfig) {
    console.warn(`[Form Utils] Form element config  "${fieldName}" is not in parent config ${formConfig.name}`);
    return;
  }

  if (fieldConfig.attributes) {
    fieldConfig.attributes.suffix = suffix;
  } else {
    fieldConfig.attributes = { suffix };
  }
};

export const setFormFieldLabel = (fieldName: string, label: string, formConfig: FormConfig) => {
  const fieldConfig = findFormConfigChildren(fieldName, formConfig);
  if (!fieldConfig) return;

  if (fieldConfig.attributes) {
    fieldConfig.attributes.label = label;
  } else {
    fieldConfig.attributes = { label };
  }
};

export const setFormAutoCompleteFieldItems = (fieldName: string, items: any[], formConfig: FormConfig) => {
  const fieldConfig = findFormConfigChildren(fieldName, formConfig) as AutocompleteConfig;

  if (!fieldConfig) return;

  if (fieldConfig.settings) {
    fieldConfig.settings.items = items;
  } else {
    fieldConfig.settings = { items };
  }
};

export const setFormElementRelatedElements = (elementName: string, relatedElements: string[], formConfig: FormConfig) => {
  const elementConfog = findFormConfigChildren(elementName, formConfig);
  if (!elementConfog) return;

  elementConfog.relatedElements = relatedElements;
};

export const findFormConfigChildren = (fieldName: string, formConfig: FormConfig) => {
  if (!formConfig.children) return;

  return formConfig.children.find((child) => child.name === fieldName);
};
