import type { Form } from '@common/index';
import type { FormHierarchyItem } from '../models';
import { getElement } from './case.utils';
import { BasicCalculation } from '../plugins';

export class BmiCalculation extends BasicCalculation {
  protected weightItem = { name: 'weight' } as FormHierarchyItem;
  protected heightItem = { name: 'height' } as FormHierarchyItem;
  protected bmiItem = { name: 'bmi' } as FormHierarchyItem;

  public override calculate(_form: Form): void {
    const bmiElement = getElement(this.bmiItem, _form);
    const weightElement = getElement(this.weightItem, _form);
    const heightElement = getElement(this.heightItem, _form);

    if (weightElement.value && heightElement.value) {
      bmiElement.value = weightElement.value / (heightElement.value * heightElement.value);
    } else {
      bmiElement.value = null;
    }
  }
}

export class PpodlcoPercCalculation extends BasicCalculation {
  protected functioningSegmentsResectedItem = { name: 'functioningSegmentsResected', parents: ['segments'] } as FormHierarchyItem;
  protected dlcoPercentItem = { name: 'dlcoPercent', parents: ['spirometry'] } as FormHierarchyItem;
  protected ppodlcoPercItem = { name: 'ppodlcoperc', parents: ['spirometry'] } as FormHierarchyItem;

  public override calculate(_form: Form): void {
    const functioningSegmentsResectedElement = getElement(this.functioningSegmentsResectedItem, _form);
    const dlcoPercentElement = getElement(this.dlcoPercentItem, _form);
    const ppodlcoPercElement = getElement(this.ppodlcoPercItem, _form);

    if (functioningSegmentsResectedElement.value && dlcoPercentElement.value) {
      ppodlcoPercElement.value = dlcoPercentElement.value * (1 - functioningSegmentsResectedElement.value / 20);
    } else {
      ppodlcoPercElement.value = null;
    }
  }
}

export class Ppofev1PercentCalculation extends BasicCalculation {
  protected functioningSegmentsResectedItem = { name: 'functioningSegmentsResected', parents: ['segments'] } as FormHierarchyItem;
  protected atelectaticSegmentsAtOperationItem = { name: 'atelectaticSegmentsAtOperation', parents: ['segments'] } as FormHierarchyItem;
  protected fev1LitresItem = { name: 'fev1Litres', parents: ['spirometry'] } as FormHierarchyItem;
  protected ppofev1PercentItem = { name: 'ppofev1Percent', parents: ['spirometry'] } as FormHierarchyItem;

  public override calculate(_form: Form): void {
    const functioningSegmentsResectedElement = getElement(this.functioningSegmentsResectedItem, _form);
    const fev1LitresElement = getElement(this.fev1LitresItem, _form);
    const atelectaticSegmentsAtOperationElement = getElement(this.atelectaticSegmentsAtOperationItem, _form);
    const ppofev1PercentPercElement = getElement(this.ppofev1PercentItem, _form);

    if (functioningSegmentsResectedElement.value && fev1LitresElement.value && atelectaticSegmentsAtOperationElement.value) {
      ppofev1PercentPercElement.value =
        fev1LitresElement.value *
        ((20 - atelectaticSegmentsAtOperationElement.value - functioningSegmentsResectedElement.value) /
          (20 - atelectaticSegmentsAtOperationElement.value));
    } else {
      ppofev1PercentPercElement.value = null;
    }
  }
}

export class Fev1lDivFvclCalculation extends BasicCalculation {
  protected fev1LitresItem = { name: 'fev1Litres', parents: ['spirometry'] } as FormHierarchyItem;
  protected fvcLitresItem = { name: 'fvcLitres', parents: ['spirometry'] } as FormHierarchyItem;
  protected fev1lDivFvclItem = { name: 'fev1ldivfvcl', parents: ['spirometry'] } as FormHierarchyItem;

  public override calculate(_form: Form): void {
    const fev1LitresElement = getElement(this.fev1LitresItem, _form);
    const fvcLitresElement = getElement(this.fvcLitresItem, _form);
    const fev1lDivFvclElement = getElement(this.fev1lDivFvclItem, _form);

    if (fev1LitresElement.value && fvcLitresElement.value) {
      fev1lDivFvclElement.value = fev1LitresElement.value / fvcLitresElement.value;
    } else {
      fev1lDivFvclElement.value = null;
    }
  }
}
