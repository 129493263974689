import type {
  AsyncAutocompleteSettings,
  Form,
  FormElement,
  FormElementPluginConfig,
  FormElementState,
  FormPluginSettings,
  SelectableFormElement
} from '@common/lib/modules/form';
import { FormElementPlugin } from '@common/lib/modules/form';

export interface AsyncAutocompleteAdditionalParamPluginSettings extends FormPluginSettings {
  targetElementNames: string[];
  paramName?: string;
}

export type AsyncAutocompleteAdditionalParamPluginConfig = FormElementPluginConfig<AsyncAutocompleteAdditionalParamPluginSettings>;

export class AsyncAutocompleteAdditionalParamPlugin extends FormElementPlugin {
  public constructor() {
    super();
    this._name = 'asyncAutocompleteAdditionalParam';
  }

  public override onValueChange(_element: FormElement<any, FormElementState>, _value: any): void {
    if (!_value) {
      return;
    }

    const form = _element.form as Form;

    this.settings.targetElementNames.forEach((targetElementName) => {
      const contextNameElement = form.getChild(targetElementName) as SelectableFormElement;
      const settings = contextNameElement.settings as AsyncAutocompleteSettings;
      if (!settings.additionalParams) {
        settings.additionalParams = {};
      }
      let paramName = _element.name;
      if (this.settings.paramName) {
        paramName = this.settings.paramName;
      }
      settings.additionalParams[paramName] = _value;
    });
  }
}
