import { calculationsStepFormConfig } from './calculations-step-form.config';
import { demographicStepFormConfig } from './demographic-step-form.config';
import { opStepFormConfig } from './op-step-form.config';
import { postOpStepFormConfig } from './post-op-step-form.config';
import { preOpStepFormConfig } from './pre-op-step-form.config';

export * from './demographic-step-form.config';
export * from './post-op-step-form.config';
export * from './pre-op-step-form.config';
export * from './op-step-form.config';
export * from './calculations-step-form.config';

export const LUNG_REGISTER_STEPS = [
  {
    title: 'Demographic',
    formConfig: demographicStepFormConfig
  },
  {
    title: 'PreOp',
    formConfig: preOpStepFormConfig
  },
  {
    title: 'Op',
    formConfig: opStepFormConfig
  },
  {
    title: 'PostOp',
    formConfig: postOpStepFormConfig
  },
  {
    title: 'Calculations',
    formConfig: calculationsStepFormConfig
  }
];
