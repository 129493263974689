export default {
  app: {
    title: 'Ests'
  },

  pages: {
    index: 'Home'
  },
  menu: {
    logout: 'Logout',
    personalData: 'Personal data',
    cases: 'Cases',
    submittedCases: 'Submitted cases',
    analytics: 'Analytics',
    dashboard: 'Dashboard'
  },

  customer: {
    details: {
      label: {
        name: 'Név',
        email: 'Email',
        status: 'Státusz',
        delegate: 'Területi képviselő'
      }
    },
    list: {
      header: {
        name: 'Név',
        email: 'Email cím',
        status: 'Státusz',
        delegate: 'Területi képviselő'
      }
    },
    status: {
      active: 'Aktív',
      inactive: 'Inaktív'
    },
    filter: {
      status: 'Státusz szűrő'
    },
    dialog: {
      onBack: {
        title: 'Mentetlen adatok',
        text: 'Az űrlap mentetlen adatokat tartalmaz! Biztosan visszalép? Ezzel a mentetlen adatok elvesznek.'
      }
    }
  },
  label: {
    yes: 'Igen',
    no: 'Nem'
  },

  button: {
    edit: 'Edit',
    open: 'Open',
    delete: 'Delete',
    save: 'Save',
    finalize: 'Finalize',
    back: 'Back',
    cancel: 'Cancel',
    submit: 'Submit',
    approve: 'Approve',
    deny: 'Deny',
    summary: 'Summary'
  },

  filter: {
    search: 'Search'
  },

  case: {
    baseData: {
      title: 'Base data'
    },
    requiredFieldSwitcher: {
      label: 'Show only mandatory fields',
      enabled: ' (On)',
      disabled: ' (Off)'
    },
    label: {
      case: 'Case',
      id: 'Identifier',
      identifier: 'Identifier',
      registerName: 'Register name',
      state: 'Status',
      caseDate: 'Case date',
      dateFrom: 'Date from',
      dateTo: 'Date to',
      createCase: 'Create new case',
      register: 'Register',
      surgeons: 'Surgeons',
      assistants: 'Assistants',
      createDate: 'Created at',
      operationDate: 'Operation date',
      surgeryDate: 'Surgery date'
    },
    state: {
      DRAFT: 'In progress',
      WAITING: 'Waiting for approval',
      APPROVED: 'Approved',
      DENIED: 'Denied',
      CLOSED: 'Closed'
    },
    dialog: {
      submit: {
        title: 'Confirm submit case',
        text: 'Are you sure you submit the case for approval?'
      },
      approve: {
        title: 'Confirm case approval',
        text: 'Are you sure you want to approve the case?'
      },
      deny: {
        title: 'Confirm case deny',
        text: 'Are you sure you want to deny the case?'
      }
    },
    notification: {
      createSuccess: 'Case creation was successfull',
      createError: 'Error during case creation',
      submitSuccess: 'Case submission was successfull',
      submitError: 'Error during case submission',
      approveSuccess: 'Case approval was successfull',
      approveError: 'Error during case approval',
      denySuccess: 'Case deny was successfull',
      denyError: 'Error during case deny'
    }
  },
  dashboard: {
    greeting: 'Welcome',
    caseStat: {
      completed: 'Completed cases',
      filled: 'Filled cases',
      inProgress: 'In Progress cases'
    }
  },

  form: {
    validator: {
      dateIsBetween: {
        invalidMessage: 'The date must be between {dateFrom} and {dateTo}'
      },
      numberIsBetween: {
        invalidMessage: 'The number must be between {numberFrom} and {numberTo}'
      },
      checkboxLength: {
        invalidMessage: 'The selectable boxes are: {min} and {max}',
        invalidMessageMin: 'The minimum selectable boxes are:{min}',
        invalidMessageMax: 'The maximum selectable boxes are:{max}'
      }
    }
  },

  REGISTER: {
    1000: {
      TEXT: {
        SPIROMETRY: '<h2>Spirometry</h2>',
        SEGMENTS: '<h2>Segments</h2>',
        STAGING: '<h2>Staging</h2>',
        PRIMER_TUMOR_TNM: '<h3>Primer Tumor TNM</h3>',
        OESOPHAGUS: '<h3>Oesophagus TNM</h3>',
        CLINICAL: '<h4>Clinical TNM</h4>',
        PATHOLOGICAL: '<h4>Pathological TNM</h4>',
        DIAGNOSIS_DATA: '<h3>Diagnosis</h3>',
        PERFORMANCE: '<h2>Performance</h2>',
        COMORBIDITY: '<h2>Comorbidity</h2>',
        PROCEDURE_BASE: '<h2>Procedures</h2>',
        PLANNED_PROCEDURES: '<h2>Planned procedures</h2>',
        PREFORMED_PROCEDURES: '<h2>Performed procedures</h2>',

        LUNG_SUBGROUP_DATA: '<h3>Lung related procedures</h3>',
        PLEURA_SUBGROUP_DATA: '<h3>Pleura related procedures</h3>',
        CHEST_WALL_SUBGROUP_DATA: '<h3>Chest wall related procedures</h3>',
        TRACHEA_BRONCHUS_SUBGROUP_DATA: '<h3>Trachea-Bronchus related procedures</h3>',
        MEDIASTINUM_SUBGROUP_DATA: '<h3>Mediastinum related procedures</h3>',
        UPPER_GI_SUBGROUP_DATA: '<h3>Upper GI related procedures</h3>',
        DIAPHRAGM_SUBGROUP_DATA: '<h3>Diaphragm related procedures</h3>',
        LYMPHNODES_DATA: '<h2>Lymphnodes</h2>',
        OP_ACCESS_DATA: '<h2>Operation access data</h2>',
        BILOBECTOMY_DATA: '<h3>Bilobectomy</h3>',
        PNEUMONECTOMY_DATA: '<h3>Pneumonectomy</h3>',
        ROBOTIC_DATA: '<h2>Robotic operation data</h2>'
      }
    }
  }
};
