import { AuthApi } from '../../auth';
import { AuthHeaderPlugin, DefaultResponsePlugin, RefreshTokenPlugin } from '../';
import { createApi } from './create-api.utils';
import { AutocompleteApi } from '../../form/services/autocomplete-api.service';
import { HtmlContentApi } from '../../html-content/services/html-content-api.service';
import { TranslationsApi } from '../../i18n/services/translations-api.service';
import { ValidatorApi } from '../../form/services/validator-api.service';

const modules = {
  auth: new AuthApi(),
  autocomplete: new AutocompleteApi(),
  htmlContent: new HtmlContentApi(),
  translation: new TranslationsApi(),
  validator: new ValidatorApi()
};

const commonApi = createApi({ modules });
commonApi.registerPlugins([DefaultResponsePlugin, AuthHeaderPlugin, RefreshTokenPlugin]);

export const useCommonApi = () => commonApi.getModules();
