import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    name: 'home',
    path: '/',
    component: () => import('../pages/DashboardPage.vue'),
    meta: {
      requiresAuth: true
    }
  }
];

export default routes;
