import { ApiModule } from '../../api/services';
import type { Credentials, AuthTokens, PasswordResetData, PersonalData, ChangePasswordData } from '../';

export class AuthApi extends ApiModule {
  protected authEndpoint = '/auth';
  protected userEndpoint = '/user';

  public login(credentials: Credentials) {
    return this.api.post<AuthTokens, typeof credentials>(`${this.authEndpoint}/login`, credentials);
  }

  public refreshToken(refreshToken: string) {
    return this.api.post<AuthTokens, { refreshToken: string }>(`${this.authEndpoint}/refresh`, { refreshToken: refreshToken });
  }

  public forgotPassword(email: string) {
    return this.api.get(`/public/reset-password`, { params: { email } });
  }

  public setPassword(passwordResetData: PasswordResetData) {
    return this.api.post<void, PasswordResetData>(`/public/change-password`, passwordResetData);
  }

  public loadPersonalData() {
    return this.api.get<PersonalData>(`/me`);
  }

  public savePersonalData(personalData: PersonalData) {
    return this.api.put<PersonalData, PersonalData>(`${this.userEndpoint}/me`, personalData);
  }

  public changePassword(changePasswordData: ChangePasswordData) {
    return this.api.post<void, ChangePasswordData>(`${this.userEndpoint}/change-password`, changePasswordData);
  }
}
