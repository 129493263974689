import { FormValidator, isEmpty, type FormValidatorConfig, type FormValidatorSettings } from '@common/index';

export interface DateIsBetweenValidatorSettings extends FormValidatorSettings {
  dateFrom: Date;
  dateTo: Date;
  allowEqual: boolean;
}

export type DateIsBetweenValidatorConfig = FormValidatorConfig<DateIsBetweenValidatorSettings>;

export class DateIsBetweenValidator extends FormValidator<DateIsBetweenValidatorSettings> {
  constructor() {
    super();
    this._name = 'dateIsBetween';
    this._settings = {
      invalidMessage: 'form.validator.dateIsBetween.invalidMessage',
      needTranslate: true,
      dateFrom: new Date(),
      dateTo: new Date(),
      allowEqual: true
    };
  }

  public override async onValidate(value: any) {
    if (isEmpty(value)) {
      this.reset();
      return;
    }

    const dateValue = new Date(value);
    this._valid = this.settings.allowEqual
      ? dateValue <= this.settings.dateTo && dateValue >= this.settings.dateFrom
      : value > this.settings.dateFrom && dateValue < this.settings.dateTo;

    if (!this._valid) {
      this.addMessage(this.settings.invalidMessage, 'error', {
        dateFrom: this.settings.dateFrom.getFullYear() + '.' + (this.settings.dateFrom.getMonth() + 1),
        dateTo: this.settings.dateTo.getFullYear() + '.' + (this.settings.dateTo.getMonth() + 1)
      });
    }
  }
}
