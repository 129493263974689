import { FormValidator, isEmpty, type FormValidatorConfig, type LengthValidatorSettings } from '@common/index';

export type CheckboxLengthValidatorSettings = LengthValidatorSettings;
export type CheckboxLengthValidatorConfig = FormValidatorConfig<CheckboxLengthValidatorSettings>;

export class CheckboxLengthValidator extends FormValidator<CheckboxLengthValidatorSettings> {
  constructor() {
    super();
    this._name = 'checkboxLength';
    this._settings = {
      invalidMessage: 'form.validator.checkboxLength.invalidMessage',
      invalidMessageMin: 'form.validator.checkboxLength.invalidMessageMin',
      invalidMessageMax: 'form.validator.checkboxLength.invalidMessageMax',
      needTranslate: true
    };
  }

  public override async onValidate(values: any) {
    if (isEmpty(values)) {
      this.reset();
      return;
    }
    const min = Math.max(this._settings.min || 0, 0);
    const max = this._settings.max !== undefined ? Math.max(this._settings.max || 0, min) : undefined;
    const inclusive = this._settings.inclusive || false;

    this._valid = this.checkAllValid(values, inclusive, min, max);

    if (!this._valid) {
      this.setMessage(min, max);
    }
  }

  protected checkAllValid = (values: any, inclusive: boolean, min: number, max: number | undefined) => {
    let valid = true;

    const length = values.length;
    const isValid = inclusive
      ? length >= min && (max !== undefined ? length <= max : true)
      : length > min && (max !== undefined ? length < max : true);
    if (!isValid) {
      valid = false;
    }

    return valid;
  };

  protected setMessage = (min: number, max: number | undefined) => {
    let message;
    let placeholders;

    if (min > 0 && max !== undefined) {
      message = this._settings.invalidMessage;
      placeholders = { min, max };
    } else if (min > 0) {
      message = this._settings.invalidMessageMin;
      placeholders = { min };
    } else {
      message = this._settings.invalidMessageMax;
      placeholders = { max };
    }

    this.addMessage(message, 'error', placeholders);
  };
}
