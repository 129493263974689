export default {
  app: {
    title: 'Ests'
  },

  pages: {
    index: 'Kezdőlap'
  },
  menu: {
    logout: 'Kijelentkezés',
    personalData: 'Személyes adatok',
    analytics: 'Kimutatások'
  },

  customer: {
    details: {
      label: {
        name: 'Név',
        email: 'Email',
        status: 'Státusz',
        delegate: 'Területi képviselő'
      }
    },
    list: {
      header: {
        name: 'Név',
        email: 'Email cím',
        status: 'Státusz',
        delegate: 'Területi képviselő'
      }
    },
    status: {
      active: 'Aktív',
      inactive: 'Inaktív'
    },
    filter: {
      status: 'Státusz szűrő'
    },
    dialog: {
      onBack: {
        title: 'Mentetlen adatok',
        text: 'Az űrlap mentetlen adatokat tartalmaz! Biztosan visszalép? Ezzel a mentetlen adatok elvesznek.'
      }
    }
  },
  label: {
    yes: 'Igen',
    no: 'Nem'
  },

  button: {
    edit: 'Szerkesztés',
    open: 'Megtekintés',
    delete: 'Törlés',
    save: 'Mentés',
    finalize: 'Véglegesítés',
    back: 'Vissza',
    cancel: 'Mégse',
    summary: 'Összegzés'
  },

  filter: {
    search: 'Keresés'
  },

  case: {
    label: {
      case: 'Eset',
      id: 'Azonosító',
      createDate: 'Létrehozva',
      operationDate: 'Operáció',
      assistants: 'Asszisztensek'
    },
    state: {
      DRAFT: 'Eset folyamatban'
    }
  }
};
