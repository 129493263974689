import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import { createVuetify } from '@common/lib/modules/vuetify';
import { createRouter } from '@common/lib/modules/router';
import { createI18n } from '@common/lib/modules/i18n';
import { createAuthGuard } from '@common/lib/modules/auth/services/auth-router.service';
import routes from './app/routes';
import themes from './app/themes';
import messages from './i18n';
import './assets/scss/styles.scss';
import { customIcons } from './assets/icons/icons';
import { initForms } from './app/modules/admin/utils';
import { initCaseForms } from './app/modules/case/utils';

const app = createApp(App);
const pinia = createPinia();
const router = createRouter(routes);
const i18n = createI18n('en', messages);
const vuetify = createVuetify(i18n, themes, customIcons);

createAuthGuard(router, 'login');

initForms();
initCaseForms();

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(vuetify);
app.mount('#app');
