<script setup lang="ts">
const props = defineProps({
  label: {
    type: String
  }
});
</script>

<template>
  <div
    v-if="props.label"
    v-html="props.label"></div>
</template>
