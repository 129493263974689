import type { Form, FormElement, FormElementPluginConfig, FormPluginSettings } from '@common/lib/modules/form';
import { FormElementPlugin } from '@common/lib/modules/form';

export interface BasicCalculatedPluginSettings extends FormPluginSettings {
  calculations: () => BasicCalculation[];
}

export type BasicCalculatedPluginSettingsConfig = FormElementPluginConfig<BasicCalculatedPluginSettings>;

export class BasicCalculatedPlugin extends FormElementPlugin {
  public constructor() {
    super();
    this._name = 'basicCalculatedPlugin';
  }

  public override onInput(_element: FormElement<any>, _value: any, _event: InputEvent): void {
    const form = _element.form as Form;
    const calcs = this._settings.calculations();
    calcs.forEach((c) => {
      c.calculate(form);
    });
  }
}

export abstract class BasicCalculation {
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  public calculate(_form: Form): void {}
}
