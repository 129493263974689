import type { DatePickerConfig, FormConfig, RadioGroupConfig, TextConfig } from '@common/index';
import type { BasicCalculatedPluginSettings } from '../../plugins';
import { BmiCalculation } from '../../utils';
import type { DateIsBetweenValidatorSettings, NumberIsBetweenValidatorSettings } from '../../validators';

export const demographicStepFormConfig: FormConfig = {
  name: 'Demografic',
  plugins: [{ plugin: 'progress' }],
  attributes: {
    color: 'primary',
    variant: 'outlined',
    density: 'compact'
  },
  settings: { validation: { onInput: false } },
  children: [
    {
      name: 'dateOfBirth',
      component: 'datepicker',
      settings: { needTranslate: true, grid: { cols: 12 } },
      attributes: {
        label: 'REGISTER.1000.TEXT.DATEOFBIRTH',
        returnFormatted: 'yyyy-MM',
        format: 'yyyy-MM',
        closeCalendarOnPick: true
      },
      validators: [
        { validator: 'required' },
        {
          validator: 'dateIsBetween',
          settings: {
            dateFrom: new Date('1900-01-01'),
            dateTo: new Date(),
            allowEqual: true
          } as DateIsBetweenValidatorSettings
        }
      ]
    } as DatePickerConfig,
    {
      name: 'gender',
      component: 'radioGroup',
      settings: {
        needTranslate: true,
        needItemTranslate: true,
        direction: 'inline',
        grid: { cols: 12 },
        items: [
          { title: 'REGISTER.1000.ANSWER.GENDER.0', value: 0 },
          { title: 'REGISTER.1000.ANSWER.GENDER.1', value: 1 },
          { title: 'REGISTER.1000.ANSWER.GENDER.2', value: 2 }
        ]
      },
      attributes: { label: 'REGISTER.1000.TEXT.GENDER' },
      validators: [{ validator: 'required' }]
    } as RadioGroupConfig,
    // {
    //   name: 'ageAtSurgery',
    //   component: 'text',
    //   settings: {
    //     needTranslate: true,
    //     grid: 12,
    //     defaultValue: 0
    //   },
    //   attributes: {
    //     label: 'REGISTER.1000.TEXT.AGEATSURGERY',
    //     type: 'number',
    //     step: 1
    //   },
    //   validators: [{ validator: 'required' }]
    // } as TextConfig,
    {
      name: 'height',
      component: 'text',
      settings: {
        needTranslate: true,
        grid: { cols: 12, md: 5 },
        defaultValue: 0
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.HEIGHT',
        type: 'number',
        step: 0.01,
        suffix: 'm'
      },
      validators: [
        { validator: 'required' },
        {
          validator: 'numberIsBetween',
          settings: {
            numberFrom: 0,
            numberTo: 3,
            allowEqual: true
          } as NumberIsBetweenValidatorSettings
        }
      ],
      plugins: [
        {
          plugin: 'basicCalculatedPlugin',
          settings: {
            calculations: () => [new BmiCalculation()]
          } as BasicCalculatedPluginSettings
        }
      ]
    } as TextConfig,
    {
      name: 'weight',
      component: 'text',
      settings: {
        needTranslate: true,
        grid: { cols: 12, md: 5 },
        defaultValue: 0
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.WEIGHT',
        type: 'number',
        step: 0.01,
        suffix: 'kg'
      },
      validators: [
        { validator: 'required' },
        {
          validator: 'numberIsBetween',
          settings: {
            numberFrom: 0,
            numberTo: 250,
            allowEqual: true
          } as NumberIsBetweenValidatorSettings
        }
      ],
      plugins: [
        {
          plugin: 'basicCalculatedPlugin',
          settings: {
            calculations: () => [new BmiCalculation()]
          } as BasicCalculatedPluginSettings
        }
      ]
    } as TextConfig,
    {
      name: 'bmi',
      component: 'text',
      settings: {
        needTranslate: true,
        readonly: true,
        grid: { cols: 12, md: 2 },
        defaultValue: 0
      },
      attributes: {
        label: 'REGISTER.1000.TEXT.BMI',
        type: 'number',
        step: 0.01
      },
      validators: [{ validator: 'required' }]
    } as TextConfig,
    {
      name: 'smokingHistory',
      component: 'radioGroup',
      settings: {
        needTranslate: true,
        needItemTranslate: true,
        direction: 'inline',
        items: [
          { title: 'REGISTER.1000.ANSWER.SMOKINGHISTORY.0', value: 0 },
          { title: 'REGISTER.1000.ANSWER.SMOKINGHISTORY.1', value: 1 },
          { title: 'REGISTER.1000.ANSWER.SMOKINGHISTORY.2', value: 2 },
          { title: 'REGISTER.1000.ANSWER.SMOKINGHISTORY.3', value: 3 }
        ]
      },
      attributes: { label: 'REGISTER.1000.TEXT.SMOKINGHISTORY' },
      validators: [{ validator: 'required' }]
    } as RadioGroupConfig
  ]
};
