import type { Form, FormConfig } from '@common/index';

export enum CaseState {
  DRAFT = 'DRAFT',
  SUBMITTED = 'WAITING',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  CLOSED = 'CLOSED'
}

export interface CaseItem {
  id: number;
  code: string;
  operationDate: Date;
  state: CaseState;
  registerId: number;
  username: string;
  centerId: number;

  registerName?: string;
  centrumName?: string;
  users?: string;
}

export interface CaseData {
  id: number;
  code: string;
  registerId: number;
  centerId: number;
  operationDate: Date;
  state: CaseState;
  username: string;
  assistants: { name: string; username: string }[];
  caseSteps: CaseStepData[];
}
export interface CaseStepData {
  filledData: any;
  registerStepData: RegisterStepData;
}

export interface RegisterStepData {
  formId: number;
  id: number;
  name: string;
  registerId: number;
  stepNumber: number;
}

export interface CaseBaseData {
  id?: number;
  code: string;
  operationDate?: Date;
  state?: CaseState;
  registerId?: number;
  centerId?: number;
}

export interface CaseFilterDto {
  keyword: string;
}

export interface CaseTabData {
  caseData: CaseBaseData;
  actionType: string;
}

export interface CaseCreateDto {
  code: string;
  operationDate: Date;
  centerId: number;
  registerId: number;
}

export interface CaseUpdateDto {
  operationDate: Date;
  state: CaseState;
}

export interface CaseFillData {
  caseId: number;
  registerStepID: number;
  formID: number;
}

export enum CaseEditorAction {
  SUBMIT = 'SUBMIT',
  DENY = 'DENY',
  APPROVE = 'APPROVE'
}

export interface CaseEditorActionEvent {
  actionType: CaseEditorAction;
  caseId: number;
  payload: any;
}

export interface CaseStep<TState extends BaseStepState = BaseStepState> {
  title: string;
  state: TState;
  static?: boolean;
  onSelect?: (steps: CaseStep<any>[]) => void;
}

export interface BaseStepState {
  status: CaseStepStatus;
  hideRequiredBtn?: boolean;
  requiredState?: boolean;
}

export interface CaseStepState extends BaseStepState {
  form: Form;
  formValue: any;
  formConfig: FormConfig;
  registerStepData?: any;
}

export enum CaseStepStatus {
  EMPTY = 'empty',
  STARTED = 'started',
  FILLED = 'filled',
  DIRTY = 'dirty',
  ERROR = 'error'
}

export const CaseStepStatusMap = {
  [CaseStepStatus.EMPTY]: { color: 'grey', icon: 'mdi-none', accordionIcon: 'mdi-circle' },
  [CaseStepStatus.STARTED]: { color: 'warning', icon: 'mdi-none', accordionIcon: 'mdi-circle' },
  [CaseStepStatus.FILLED]: { color: 'success', icon: 'mdi-check', accordionIcon: 'mdi-check-circle' },
  [CaseStepStatus.DIRTY]: { color: 'warning', icon: 'mdi-pencil', accordionIcon: 'mdi-pencil-circle' },
  [CaseStepStatus.ERROR]: { color: 'error', icon: 'mdi-close', accordionIcon: 'mdi-close-circle' }
};

export interface FormHierarchyItem {
  name: string;
  parents?: string[];
}
